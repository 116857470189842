export const LOGIN_USER_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/Login/Login';
export const SEND_VERIFICATION_CODE = process.env.REACT_APP_API_ENDPOINT + 'api/User/ForgotPassword';
export const CHANGE_PASSWORD = process.env.REACT_APP_API_ENDPOINT + 'api/User/ChangePassword';
export const GET_USER_LIST = process.env.REACT_APP_API_ENDPOINT + 'api/users/AdminUser';
export const GET_DEPOTS = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorDepots';
export const GET_DISTRIBUTORS = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/Distributor';
export const GET_USER = process.env.REACT_APP_API_ENDPOINT + 'api/users/AdminUser/';
export const SKU_MAPPINGS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/hermes/SkuMappings';
export const POC_MAPPINGS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/hermes/PocMappings';
export const DISTRIBUTOR_CUSTOMERS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorCustomers/GetMissingPocMapping/';
export const HERMES_POC_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/hermes/HermesPoc';
export const DISTRIBUTOR_PRODUCTS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorProducts/GetMissingSkuMapping/'; 
export const HERMES_SKU_ENDPOINTS = process.env.REACT_APP_API_ENDPOINT + 'api/hermes/HermesSku';
export const HERMES_GET_ALL_ORDERS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributor/DistributorOrder/GetAllOrders';
export const HERMES_GET_ORDER_DETAILS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributor/DistributorOrderDetail/';
export const HERMES_DOWNLOAD_ORDER_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributor/DistributorOrder/DownloadOrderXml';
export const HERMES_GET_ALL_ORDERS_FOR_DISTRIBUTOR_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributor/DistributorOrder/GetAllOrdersByDistributor';
export const MANAGE_COMMENTS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/Comments';
export const MANAGE_PRODUCT_COMMENTS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/Comments/GetCommentsForDistributorProduct';
export const MANAGE_CUSTOMER_COMMENTS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/Comments/GetCommentsForDistributorCustomer';
export var userEditUrlByRoleId = {
    1: process.env.REACT_APP_API_ENDPOINT + 'api/users/AdminUser/',
    2: process.env.REACT_APP_API_ENDPOINT + 'api/users/TenantAdmin/',
    3: process.env.REACT_APP_API_ENDPOINT + 'api/users/TenantSupport/',
    4: process.env.REACT_APP_API_ENDPOINT + 'api/users/TenantSupervisor/',
    5: process.env.REACT_APP_API_ENDPOINT + 'api/distributors/Distributor/'
}
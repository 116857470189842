import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { toast } from 'react-toastify'
import * as actions from '../components/Actions/actions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UsersState
{
    isLoading: boolean;
    userList: any;
    userCredentials: any;
    responseMessage: string;
    distributors: any;
    depots: any;
    selectedUser: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface AttemptEditAction {
    type: 'ATTEMPT_EDIT_ACTION';
    distributors: any;
    depots: any;
    selectedUser: any;
}

interface GetUsersAction
{
    type: 'GET_USERS_ACTION';
    userList: any;
}

interface EditUserAction
{
    type: 'EDIT_USER_ACTION';
    responseMessage: string;
    selectedUser: any;
}

interface AddUserAction
{
    type: 'ADDD_USER_ACTION';
    responseMessage: string;
}

interface AttemptAction
{
    type: 'ATTEMPT_ACTION';
}

interface FinishAction {
    type: 'FINISH_ACTION';
}

interface ErrorAction {
    type: 'ERROR_ACTION';
    responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = AttemptEditAction | GetUsersAction | EditUserAction | AddUserAction | AttemptAction | FinishAction | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    attemptEdit: (userId): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (data) => { dispatch({ type: 'ATTEMPT_EDIT_ACTION', selectedUser: data.selectedUser, distributors: data.distributors, depots: data.depots }) },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error })
                    toast.error(error)
                 };
            actions.attemptEdit(userId, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    getUserList: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (userList) => { dispatch({ type: 'GET_USERS_ACTION', userList: userList }) },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.getUserList(callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    editUser: (userCredentials): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && userCredentials) {
            let callback = () => {
                dispatch({ type: 'EDIT_USER_ACTION', responseMessage: 'Success', selectedUser: userCredentials })
                toast.success('Success')
             },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.editUser(userCredentials, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    addUser: (userCredentials): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && userCredentials) {
            let callback = () => {
                dispatch({ type: 'ADDD_USER_ACTION', responseMessage: 'Success' })
                toast.success('Success')
             },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.addUser(userCredentials, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UsersState = {
    isLoading: false,
    userList: [],
    userCredentials: {},
    responseMessage: '',
    selectedUser: {},
    distributors: [],
    depots: []
};

export const reducer: Reducer<UsersState> = (state: UsersState | undefined, incomingAction: Action): UsersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ATTEMPT_ACTION':
            return {
                ...state,
                isLoading: true,
                responseMessage: ''
            };
            break;
        case 'FINISH_ACTION':
            return {
                ...state,
                isLoading: false
            };
            break;
        case 'ATTEMPT_EDIT_ACTION':
            return {
                ...state,
                distributors: action.distributors,
                depots: action.depots,
                selectedUser: action.selectedUser,
                isLoading: false
            };
            break;
        case 'GET_USERS_ACTION':
                return {
                    ...state,
                    userList: action.userList,
                    isLoading: false
                };
            break;
        case 'EDIT_USER_ACTION':
            return {
                ...state,
                isLoading: false,
                selectedUser: action.selectedUser,
                responseMessage: action.responseMessage
            };
            break;
        case 'ADDD_USER_ACTION':
            return {
                ...state,
                responseMessage: action.responseMessage
            };
            break;
        case 'ERROR_ACTION':
            return {
                ...state,
                responseMessage: state.responseMessage + ' ' + action.responseMessage,
                isLoading: false
            }
    }

    return state;
};

import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import { toast } from "react-toastify";
import App from "./App";
import { ThemeProvider } from "styled-components";
import registerServiceWorker from "./registerServiceWorker";

import GlobalStyle, { theme } from "./GlobalStyle";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";
import { store } from "store/configureStore";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "i18n";
import locale from "antd/locale/en_GB";
import { ConfigProvider } from "antd";
import "dayjs/locale/en-gb";
toast.configure({ autoClose: 14000 });

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: () => {
        toast.error("Something went wrong");
      },
    },
    queries: {
      onError: () => {
        toast.error("Something went wrong");
      },
      refetchOnWindowFocus: false,
    },
  },
});
// let lang = localStorage.getItem("lang");
//
// if (lang === null) {
//   lang = "bg";
// }
// // i18next.changeLanguage("bg");

const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <I18nextProvider i18n={i18next} defaultNS={"translation"}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <ConfigProvider locale={locale}>
                <App />
              </ConfigProvider>
            </ThemeProvider>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </StrictMode>,
);

registerServiceWorker();

import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import * as endpoints from '../constants/endpoints'
import Cookies from 'universal-cookie';
import FileSaver from 'file-saver';

const cookies = new Cookies();

export function getOrdersData(isSeen, callback, error) {
    var url = endpoints.HERMES_GET_ALL_ORDERS_FOR_DISTRIBUTOR_ENDPOINT;
    if (isSeen !== null) {
        url += '?isSeen=' + isSeen;
    }
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function getOrderDetails(orderId, callback, error) {
    var url = endpoints.HERMES_GET_ORDER_DETAILS_ENDPOINT;
    url += orderId;
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function downloadOrder(orderId, callback, error) {
    var url = endpoints.HERMES_DOWNLOAD_ORDER_ENDPOINT,
        fileName = '';
    url += '?orderId=' + orderId;
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(function (response) {
            var x = response.headers
                .get("content-disposition");
            fileName = response.headers
                .get("content-disposition")
                .split(';')[1]
                .split('=')[1];
            return response.blob();
        }).then(function (blob) {
            FileSaver.saveAs(blob, fileName);
            try {
                callback(blob);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

interface Props {
    title: string,
    value: string | number,
    color?: string,
}

const DashBoxStyle = styled.div`
    width: 100%;
    background: ${({ theme, color })=> theme.colors[color]};;
    border-radius: 4px;
    padding: 20px;
    margin: 10px 0;
`

const Value = styled.p`
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
    border-bottom: 1px solid #ffffff3d;
    padding-bottom: 18px;
`

const Title = styled.h2`
    color: #fff;
    font-size: 16px;
`

const DashBoxComponent: FunctionComponent<Props> = ({ title, value, color = 'primary' }) => (
    <DashBoxStyle {... { color }}>
        <Value>{value}</Value>
        <Title>{title}</Title>
    </DashBoxStyle>
  )

export default DashBoxComponent

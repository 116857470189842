import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as DistributorDashboardStore from "../../store/DistributorDashboardReducer";
import { Row, Col } from "react-grid-system";
import Loader from "../Loader";
import Box from "../Box";
import Headline from "../Headline";
import DashBox from "../DashBox";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import withRouter, { WithRouterHocProps } from "../../helpers/WithRouterHOC";

// At runtime, Redux will merge together...
type DistributorDashboardProps =
  DistributorDashboardStore.DistributorDashboardState & // ... state we've requested from the Redux store
    typeof DistributorDashboardStore.actionCreators; // ... plus action creators we've requested

var isLoaded = false;

class DistributorDashboard extends React.Component<
  DistributorDashboardProps & WithRouterHocProps
> {
  state = {
    numberOfOrdersForMonth: {},
    numberOfCaseForMonth: {},
    numberOfOrdersForQuarter: {},
    numberOfCaseForQuarter: {},
    productsForMonth: {},
    productsForQuarter: {},
  };

  componentDidMount = () => {
    //this.props.getOrdersDataForMonth();
    //this.props.getOrdersDataForQuarter();
    //this.props.getChartsData();
    if (!this.props.isLoaded) {
      this.props.initializeData();
      isLoaded = false;
    }
  };

  constructor(props) {
    super(props);
  }

  resetBtnState() {
    let buttons = document.getElementsByTagName("button");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].disabled = false;
    }
  }

  toggleMapBtnState = (state) => {
    var createBtn = document.getElementById("create-map-btn");
    if (createBtn) {
      (createBtn as HTMLInputElement).disabled = state;
    }
  };

  public render() {
    const {
      responseMessage,
      ordersDataForMonth,
      ordersDataForQuarter,
      chartsData,
      isLoading,
    } = this.props;
    const Table = styled.table`
      text-align: left;
      width: 100%;

      thead th {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.dark};
      }

      &:not(.table-bordered) thead td,
      &:not(.table-bordered) thead th {
        border-top: 0;
      }

      td,
      th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #ebedf2;
      }

      tbody tr:nth-of-type(odd) {
        background-color: #f7f8fa;
      }
    `;

    const DashBoxes = styled.div`
      margin-bottom: 40px;
    `;
    var dataMtd = chartsData ? (chartsData[0] ? chartsData[0] : "") : "";
    const mtdData = {
      labels: dataMtd ? dataMtd["labels"] : [],
      datasets: [
        {
          label: dataMtd ? dataMtd["label"] : "",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataMtd ? dataMtd["data"] : [],
        },
      ],
    };
    var dataYtd = chartsData ? (chartsData[1] ? chartsData[1] : "") : "";
    const ytdData = {
      labels: dataYtd ? dataYtd["labels"] : [],
      datasets: [
        {
          label: dataYtd ? dataYtd["label"] : "",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataYtd ? dataYtd["data"] : [],
        },
      ],
    };
    var dataYty1 = chartsData ? (chartsData[2] ? chartsData[2] : "") : "";
    var dataYty2 = chartsData ? (chartsData[3] ? chartsData[3] : "") : "";
    const ytyData = {
      labels: dataYty1 ? dataYty1["labels"] : [],
      datasets: [
        {
          label: dataYty1 ? dataYty1["label"] : "",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataYty1 ? dataYty1["data"] : [],
        },
        {
          label: dataYty2 ? dataYty2["label"] : "",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataYty2 ? dataYty2["data"] : [],
        },
      ],
    };
    var dataStock = chartsData ? (chartsData[4] ? chartsData[4] : "") : "";
    const stockData = {
      labels: dataStock ? dataStock["labels"] : [],
      datasets: [
        {
          label: dataStock ? dataStock["label"] : "",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataStock ? dataStock["data"] : [],
        },
      ],
    };
    const monthName = new Date().toLocaleString("default", { month: "long" });
    const quarter = Math.floor((new Date().getMonth() + 3) / 3);
    var ordersForMonth = "Orders for " + monthName;
    var freeForMonth = "Free for " + monthName;
    var ordersForQuarter = "Orders for Q" + quarter;
    var freeForQuarter = "Free for Q" + quarter;
    var freeCaseForQuarter = "Free case for Q" + quarter;
    var freeCaseForMonth = "Free case for " + monthName;
    //TODO position number of orders and product lists
    //TODO change
    return isLoading ? (
      <Loader isLoading={true} background="semiLight" />
    ) : (
      <>
        <DashBoxes>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <DashBox
                    title={ordersForMonth}
                    value={
                      ordersDataForMonth
                        ? ordersDataForMonth["numberOfOrders"]
                        : ""
                    }
                  />
                </Col>
                <Col md={6}>
                  <DashBox
                    title={freeForMonth}
                    value={
                      ordersDataForMonth
                        ? ordersDataForMonth["numberOfFreeCase"]
                        : ""
                    }
                    color="default"
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <DashBox
                    title={ordersForQuarter}
                    value={
                      ordersDataForQuarter
                        ? ordersDataForQuarter["numberOfOrders"]
                        : ""
                    }
                  />
                </Col>
                <Col md={6}>
                  <DashBox
                    title={freeForQuarter}
                    value={
                      ordersDataForQuarter
                        ? ordersDataForQuarter["numberOfFreeCase"]
                        : ""
                    }
                    color="default"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </DashBoxes>

        <Headline> Your targets for {monthName} </Headline>
        <Row>
          <Col md={6}>
            <Box
              hasMargin
              hasContentGutter
              title={dataMtd ? dataMtd["label"] : ""}
            >
              {/*@ts-ignore*/}
              <Line
                {...{
                  data: {
                    labels: dataMtd ? dataMtd["labels"] : [],
                    datasets: [
                      {
                        label: dataMtd ? dataMtd["label"] : "",
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: "rgba(75,192,192,0.4)",
                        borderColor: "rgba(75,192,192,1)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "rgba(75,192,192,1)",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "rgba(75,192,192,1)",
                        pointHoverBorderColor: "rgba(220,220,220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: dataMtd ? dataMtd["data"] : [],
                      },
                    ],
                  },
                }}
              />
            </Box>
          </Col>
          <Col md={6}>
            <Box
              hasMargin
              hasContentGutter
              title={dataYtd ? dataYtd["label"] : ""}
            >
              {/*@ts-ignore*/}
              <Line {...{ data: ytdData }} />
            </Box>
          </Col>
          <Col md={6}>
            <Box hasMargin hasContentGutter title="HL for 2019/2020">
              {/*@ts-ignore*/}
              <Line {...{ data: ytyData }} />
            </Box>
          </Col>
          <Col md={6}>
            <Box
              hasMargin
              hasContentGutter
              title={dataStock ? dataStock["label"] : ""}
            >
              {/*@ts-ignore*/}
              <Line {...{ data: stockData }} />
            </Box>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Box title={freeCaseForMonth} hasMargin hasContentGutter>
              <Table>
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>hl</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersDataForMonth ? (
                    ordersDataForMonth["freeCaseProducts"] ? (
                      ordersDataForMonth["freeCaseProducts"].map(
                        (orderItem, id) => {
                          return (
                            <tr>
                              <td>{orderItem["name"]}</td>
                              <td>{orderItem["wsQuantity"]}</td>
                              <td>{orderItem["hl"]}</td>
                            </tr>
                          );
                        },
                      )
                    ) : (
                      <tr />
                    )
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </Box>
          </Col>
          <Col md={6}>
            <Box title={freeCaseForQuarter} hasMargin hasContentGutter>
              <Table>
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>hl</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersDataForQuarter ? (
                    ordersDataForQuarter["freeCaseProducts"] ? (
                      ordersDataForQuarter["freeCaseProducts"].map(
                        (orderItem, id) => {
                          return (
                            <tr>
                              <td>{orderItem["name"]}</td>
                              <td>{orderItem["wsQuantity"]}</td>
                              <td>{orderItem["hl"]}</td>
                            </tr>
                          );
                        },
                      )
                    ) : (
                      <tr />
                    )
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </Box>
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(
  connect(
    (state: ApplicationState) => state.distributorDashboard,
    DistributorDashboardStore.actionCreators,
  )(DistributorDashboard),
);

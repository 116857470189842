export const GET_DEPOTS_REGIONS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '';
export const GET_DEPOTS_FOR_DISTRIBUTOR_ID_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorDepots/GetDistributorDepotsByDistributorId';
export const GET_RECEIVED_SALES_FILES_FOR_DEPOT_ID_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorFiles/GetDistributorFilesByFileTypeByDepotId?fileType=SALES';
export const DOWNLOAD_RECEIVED_SALES_FILES_REPORT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorFiles/DownloadDistributorFileXml';
export const GET_RECEIVED_SALES_FILE_ENTRIES_FOR_FILE_ID_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorFileEntries/GetDistributorFileEntriesByDistributorFileId';
export const GET_SALES_DATA_FOR_DEPOT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '';
export const GET_STOCK_DATA_FOR_DEPOT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '';
export const GENERATE_SALES_REPORT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '';
export const GET_SALES_REPORTS_LIST_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '';
export const DOWNLOAD_GENERATED_SALES_REPORT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '';
export const MANAGE_DISTRIBUTOR_DEPOTS_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + 'api/distributors/DistributorDepots/';
import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

const FieldWrapper = styled.div`
    margin-bottom: ${({ disableMargin }) => disableMargin ? '0' : '25px'};

    label {
        font-size: ${({ size }) => size === 'large' ? '16px' : '14px'};
        color: ${({ theme })=> theme.colors.dark};
        display: inline-block;
        margin-bottom: ${({ size }) => size === 'large' ? '10px' : '8px'};
        cursor: pointer;
    }

    .form-control.is-invalid~.invalid-feedback, .form-control.is-invalid~.invalid-tooltip, .was-validated .form-control:invalid~.invalid-feedback, .was-validated .form-control:invalid~.invalid-tooltip {
        display: inline-block;
    }

    .invalid-feedback {
        position: relative;
        display: inline-block;
        color: #fff;
        background: ${({ theme })=> theme.colors.danger};
        margin-top: 15px;
        padding: 6px 12px;
        font-size: 13px;
        border-radius: 2px;
        max-width: 300px;
        width: auto;

        &::before {
            content: '';
            position: absolute;
            top: -5px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid ${({ theme })=> theme.colors.danger};
        }
    }

    input,
    select {
        display: block;
        width: 100%;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e6e6e6;
        font-size: 14px;
        padding: 0 12px;
        height: ${({ size }) => size === 'large' ? '50px' : '38px'};

        &:focus {
            box-shadow: none !important;
            outline: none;
            border-color: ${({ theme }) => theme.colors.default};
        }
    }
`

interface Props {
    label?: string,
    id?: string,
    size?: 'small' | 'large',
    disableMargin?: boolean,
    children: ReactNode
}

const FieldComponent: FunctionComponent<Props> = ({ label, id, size = 'small', disableMargin = false, children }) => (
    <FieldWrapper {... { size, disableMargin }}>
        <label htmlFor={id}>{label}</label>
        {children}
    </FieldWrapper>
  )

export default FieldComponent

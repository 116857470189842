import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { toast } from 'react-toastify'
import * as actions from '../components/Actions/distributorDashboardActions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DistributorDashboardState {
    isLoading: boolean;
    responseMessage: any;
    ordersDataForMonth: any;
    ordersDataForQuarter: any;
    chartsData: any;
    isLoaded: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetOrdersDataForMonth {
    type: 'GET_ORDERS_DATA_FOR_MONTH';
    ordersDataForMonth: any;
}

interface GetOrdersDataForQuarter {
    type: 'GET_ORDERS_DATA_FOR_QUARTER';
    ordersDataForQuarter: any;
}

interface ReceiveChartData {
    type: 'RECEIVE_CHART_DATA_ACTION';
    chartsData: any;
}

interface DataIsLoaded {
    type: 'DATA_IS_LOADED';
}

interface AttemptAction {
    type: 'ATTEMPT_ACTION';
}

interface FinishAction {
    type: 'FINISH_ACTION';
}

interface ErrorAction {
    type: 'ERROR_ACTION';
    responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetOrdersDataForMonth | GetOrdersDataForQuarter | DataIsLoaded | AttemptAction | ReceiveChartData |
    FinishAction | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getOrdersDataForMonth: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (ordersDataForMonth) => { dispatch({ type: 'GET_ORDERS_DATA_FOR_MONTH', ordersDataForMonth: ordersDataForMonth }); },
                error = (error) => { dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' }) };
            actions.getAction('month', callback, error);
        }
    },
    getOrdersDataForQuarter: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (ordersDataForQuarter) => { dispatch({ type: 'GET_ORDERS_DATA_FOR_QUARTER', ordersDataForQuarter: ordersDataForQuarter }); },
                error = (error) => { dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' }) };
            actions.getAction('quarter', callback, error);
        }
    },
    getChartsData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (chartsData) => { dispatch({ type: 'RECEIVE_CHART_DATA_ACTION', chartsData: chartsData }); },
                error = (error) => { dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' }) };
            actions.getChartData(callback, error);
        }
    },
    initializeData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            //let ordersDataForMonth = () => {
            //    let callback = (ordersDataForMonth) => {
            //        dispatch({ type: 'GET_ORDERS_DATA_FOR_MONTH', ordersDataForMonth: ordersDataForMonth });
            //        ordersDataForQuarter();
            //    },
            //        error = (error) => {
            //            dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
            //            ordersDataForQuarter();
            //        };
            //    actions.getAction('month', callback, error);
            //}
            //let ordersDataForQuarter = () => {
            //    let callback = (ordersDataForQuarter) => { dispatch({ type: 'GET_ORDERS_DATA_FOR_QUARTER', ordersDataForQuarter: ordersDataForQuarter }); },
            //        error = (error) => { dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' }) };
            //    actions.getAction('quarter', callback, error);
            //}
            dispatch({ type: 'DATA_IS_LOADED' });
            let callback = (chartsData) => {
                dispatch({ type: 'RECEIVE_CHART_DATA_ACTION', chartsData: chartsData });
                //ordersDataForMonth();
            },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' });
                    //ordersDataForMonth();
                };
            actions.getChartData(callback, error);
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: DistributorDashboardState = {
    isLoading: false,
    responseMessage: '',
    ordersDataForQuarter: {},
    ordersDataForMonth: {},
    chartsData: {},
    isLoaded: false
};

export const reducer: Reducer<DistributorDashboardState> = (state: DistributorDashboardState | undefined, incomingAction: Action): DistributorDashboardState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ATTEMPT_ACTION':
            return {
                ...state,
                isLoading: true,
                responseMessage: ''
            };
            break;
        case 'FINISH_ACTION':
            return {
                ...state,
                isLoading: false
            };
            break;
        case 'GET_ORDERS_DATA_FOR_MONTH':
            return {
                ...state,
                ordersDataForMonth: action.ordersDataForMonth
            };
            break;
        case 'DATA_IS_LOADED':
            return {
                ...state,
                isLoaded: true
            };
            break;
        case 'GET_ORDERS_DATA_FOR_QUARTER':
            return {
                ...state,
                ordersDataForQuarter: action.ordersDataForQuarter,
                isLoading: false
            };
            break;
        case 'RECEIVE_CHART_DATA_ACTION':
            return {
                ...state,
                chartsData: action.chartsData,
                isLoading: false
            };
            break;
        case 'ERROR_ACTION':
            return {
                ...state,
                responseMessage: action.responseMessage,
                isLoading: false
            }
            break;
    }
    return state;
};

import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { toast } from 'react-toastify'
import * as actions from '../components/Actions/commentsActions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CommentsState
{
    isLoading: boolean;
    responseMessage: string
    commentsList: any
    newComment: any;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface LoadAllCommentsAction {
    type: 'LOAD_ALL_COMMENTS_ACTION';
    commentsList: any;
}

interface AddNewCommentAction
{
    type: 'ADD_NEW_COMMENT_ACTION';
}

interface AttemptAction
{
    type: 'ATTEMPT_ACTION';
}

interface FinishAction {
    type: 'FINISH_ACTION';
}

interface ErrorAction {
    type: 'ERROR_ACTION';
    responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = LoadAllCommentsAction | AddNewCommentAction | AttemptAction | FinishAction | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    loadAllCommentsForTypeAndId: (type, id, cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && type && id && cb) {
            let callback = (commentsList) => { dispatch({ type: 'LOAD_ALL_COMMENTS_ACTION', commentsList: commentsList }); cb(commentsList); },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error })
                    toast.error(error)
                };
            actions.loadAllCommentsForTypeAndId(type, id, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    },
    addNewComment: (comment, cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && comment && cb) {
            let callback = (newComment) => { dispatch({ type: 'ADD_NEW_COMMENT_ACTION' }); cb(newComment); },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.addNewComment(comment, callback, error);
        }
        else {
            dispatch({ type: 'FINISH_ACTION' })
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CommentsState = {
    isLoading: false,
    commentsList: [],
    newComment: {},
    responseMessage: ''
};

export const reducer: Reducer<CommentsState> = (state: CommentsState | undefined, incomingAction: Action): CommentsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ATTEMPT_ACTION':
            return {
                ...state,
                isLoading: true,
                responseMessage: ''
            };
        case 'FINISH_ACTION':
            return {
                ...state,
                isLoading: false
            };
        case 'LOAD_ALL_COMMENTS_ACTION':
            return {
                ...state,
                commentsList: action.commentsList,
                isLoading: false,
                responseMessage: ''
            };
        case 'ADD_NEW_COMMENT_ACTION':
                return {
                    ...state,
                    isLoading: false
                };
        case 'ERROR_ACTION':
            return {
                ...state,
                responseMessage: action.responseMessage,
                isLoading: false
            }
    }
    return state;
};

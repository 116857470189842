import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as AuthenticationReducer from './AuthenticationReducer';
import * as UsersReducer from './UsersReducer';
import * as SKUReducer from './SKUReducer';
import * as POCReducer from './POCReducer';
import * as OrdersReducer from './OrdersReducer';
import * as DistributorDashboardReducer from './DistributorDashboardReducer';
import * as ReceivedSalesFilesReducer from './ReceivedSalesFilesReducer';
import * as UploadDataReducer from './UploadDataReducer';
import * as CommentsReducer from './CommentsReducer';

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    authentication: AuthenticationReducer.AuthenticationState | undefined;
    users: UsersReducer.UsersState | undefined;
    sku: SKUReducer.SKUState | undefined;
    poc: POCReducer.POCState | undefined;
    orders: OrdersReducer.OrdersState | undefined;
    distributorDashboard: DistributorDashboardReducer.DistributorDashboardState | undefined;
    receivedSalesFiles: ReceivedSalesFilesReducer.SalesFilesState | undefined;
    uploadData: UploadDataReducer.UploadDataState | undefined;
    comments: CommentsReducer.CommentsState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    authentication: AuthenticationReducer.reducer,
    users: UsersReducer.reducer,
    sku: SKUReducer.reducer,
    poc: POCReducer.reducer,
    orders: OrdersReducer.reducer,
    distributorDashboard: DistributorDashboardReducer.reducer,
    receivedSalesFiles: ReceivedSalesFilesReducer.reducer,
    uploadData: UploadDataReducer.reducer,
    comments: CommentsReducer.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

import { checkHttpStatus, parseText, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import Cookies from 'universal-cookie';
import * as endpoints from '../constants/uploadDataEndpoints/uploadDataEndpoints'

const cookies = new Cookies();

export function uploadFile(file, callback, error) {
    return fetch(endpoints.UPLOAD_DATA_ENDPOINT, {
        method: 'post',
        headers: {
            'Authorization': `Bearer ${cookies.get('rawtoken')}`
        },
        body: file  
    })
        .then(checkHttpStatus)
        .then(async response => {
            try {
                callback();
            } catch (e) {
                var msg = await parseText(e);
                console.log(msg)
                error(msg);
            }
        })
        .catch(async e => {
            var msg = await parseText(e);
            console.log(msg)
            error(msg);
        })
}

export function getLastTen(callback, error) {
    return fetch(endpoints.GET_LAST_TEN_ENDPOINT, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(async response => {
            try {
                callback(response);
            } catch (e) {
                var msg = await parseText(e);
                console.log(msg)
                error(msg);
            }
        })
        .catch(async e => {
            var msg = await parseText(e);
            console.log(msg)
            error(msg);
        })
}


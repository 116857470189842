import React, { useMemo } from "react";

import { ColDef } from "ag-grid-community";
import { COLUMN_TYPE_NAMES } from "constants/agGridConstants";
import { dateFormatter } from "components/Utils/agGrid/valueFormatters";
import Button from "components/Button";

type Props = { downloadReceivedSalesFileReport: (reportId: number) => void };
const useReceivedSalesFilesColDefs = ({
  downloadReceivedSalesFileReport,
}: Props) => {
  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: "depotName",
        headerName: "Депо",
        flex: 2,
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "extractDate",
        headerName: "Дата",
        valueFormatter: dateFormatter,
      },
      {
        field: "dateCreated",
        headerName: "Получено на",
        valueFormatter: dateFormatter,
      },
      {
        field: "status",
        headerName: "Файл статус",
      },
      { field: "totalEntries", headerName: "Брой редове" },
      { field: "datesCount", headerName: "Брой дати" },
      { field: "datesList", headerName: "Списък дати" },
      {
        field: "fileName",
        headerName: "Име на файла",
        flex: 2,
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
      {
        field: "action",
        headerName: "Действие",
        cellRenderer: (params) => (
          <Button
            onClick={() => downloadReceivedSalesFileReport(params.data.id)}
          >
            Download
          </Button>
        ),
      },
    ],
    [],
  );

  return { columnDefs };
};

export default useReceivedSalesFilesColDefs;

import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import * as endpoints from '../constants/distributorDashboardEndpoints'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function getAction(type, callback, error) {
    var url;
    switch (type) {
        case 'month':
            url = endpoints.GET_DISTRIBUTOR_ORDERS_FREE_CASE_FOR_PERIOD_ENDPOINT + '?interval=month';
            break;
        case 'quarter':
            url = endpoints.GET_DISTRIBUTOR_ORDERS_FREE_CASE_FOR_PERIOD_ENDPOINT + '?interval=quarter';
            break;
    }

    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function getChartData(callback, error) {
    var url = endpoints.GET_CHART_DATA_ENDPOINT;
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

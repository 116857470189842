import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import * as endpoints from '../constants/endpoints'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function pocAction(method, callback, error, pocMapping) {
    var url = endpoints.POC_MAPPINGS_ENDPOINT,
        parseFunc = method !== 'delete' ? parseJSON : '';
    if (method === 'put' || method === 'delete') {
        url += '/' + pocMapping.id;
        delete pocMapping.id;
    }
    return fetch(url, {
        method: method,
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`},
        body: method === 'put' || method === 'post' ? JSON.stringify(pocMapping) : ''
    })
        .then(checkHttpStatus)
        .then(parseFunc)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function getCustomers(type, callback, error) {
    var url = type === 'distributors' ? endpoints.DISTRIBUTOR_CUSTOMERS_ENDPOINT : type === 'hermes' ? endpoints.HERMES_POC_ENDPOINT : '';
    return fetch(url, {
        method: 'get',
        headers: {'Accept': '*/*','Content-Type': 'application/json-patch+json','Authorization': `Bearer ${cookies.get('rawtoken')}`}
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

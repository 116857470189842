import React, { FunctionComponent } from 'react'
import Field from './Field'

interface T {
    value: string | number,
    label: string,
}

interface Props {
    label?: string,
    id?: string,
    size?: 'small' | 'large',
    options: Array<T>,
    disableMargin?: boolean,
    // All other props
    [x:string]: any;
}

const SelectComponent: FunctionComponent<Props> = ({ label, id, size = 'small', disableMargin, options, ...restProps }) => (
    <Field {... { id, label, size, disableMargin }}>
        <select {... restProps} {... { id }}>
            {options.map(({ value, label }) => {
                return (<option value={value}>{label}</option>)
            })}
        </select>
    </Field>
  )

export default SelectComponent

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TickIcon } from '../svg'

const CheckboxWrapper = styled.div`
  margin-bottom: 12px;
`

const Label = styled.label`
  display: inline-block;

  & span {
    display: block;
    position: relative;
    color: ${({ theme })=> theme.colors.dark};
    font-weight: ${({ weight })=> weight};
    min-height: 26px;
    padding-top: 3px;
    line-height: 1.3;
    cursor: pointer;
    user-select: none;
    transition: color 0.3s ease;
    padding-left: 32px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      border: 1px solid ${({ theme })=> theme.colors.border};
      background: ${({ theme })=> theme.colors.light};
      transition: background 0.3s ease;
    }

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        font-size: 14px;
        padding-left: 28px;

        &::before {
          width: 18px;
          height: 18px;
        }
      }

      @media ${theme.mediaQueries.smUp} {
        font-size: 15px;
        padding-left: 32px;

        &::before {
          width: 22px;
          height: 22px;
        }
      }
    `}

    &::before {
      border-radius: 3px;
    }

    svg {
      position: absolute;
      top: 4px;
      left: 4px;
      opacity: 0;
      fill: ${({ theme })=> theme.colors.default};

      ${({ theme }) => `
        @media ${theme.mediaQueries.xsOnly} {
          width: 12px;
          height: 12px;
        }

        @media ${theme.mediaQueries.smUp} {
          width: 16px;
          height: 16px;
        }
      `}
    }
  }

  & input {
    display: none;

    &:checked ~ span {
      & svg,
      &::after {
        opacity: 1;
      }
    }
  }
`

interface Props {
    label?: string,
    // All other props
    [x:string]: any;
}

const CheckboxComponent: FunctionComponent<Props> = ({ label, children, ...restProps }) => (
  <CheckboxWrapper>
    <Label>
      <input type='checkbox' {...restProps} />
      <span>
        <TickIcon />
        {label}
      </span>
    </Label>
  </CheckboxWrapper>
)

export default CheckboxComponent

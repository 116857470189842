import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as UsersStore from '../../store/UsersReducer';
import * as actions from '../Utils/actions'
import 'react-dropdown/style.css';
import Form from '../Form'
import Button from '../Button'
import TextInput from '../TextInput'
import Select from '../Select'
import Checkbox from '../Checkbox'
import Box from '../Box'
import { Row, Col } from 'react-grid-system';
import { EditIcon, BackIcon } from '../../svg'
// @ts-ignore
import {RouteComponentProps} from "react-router";

type UsersProps =
    UsersStore.UsersState &
    typeof UsersStore.actionCreators &
    RouteComponentProps<{ userId: any }>;

class EditUserForm extends React.PureComponent<UsersProps> {

    constructor(props) {
        super(props);
    }

    public state = {
        name: '',
        username: '',
        email: '',
        roleId: '',
        distributorId: '',
        validated: false,
        depotsTabs: ['West', 'South', 'East', 'North']
    };

    componentDidMount() {
        const showDepots = actions.rolesEnum[this.state.roleId] === 'TenantSupervisor';
        const selectedUserId = this.props.match.params.userId;
        this.props.attemptEdit(selectedUserId);
    }

    componentWillReceiveProps(nextProps, prevState) {
        const { selectedUser } = nextProps;
        this.setState({
            name: selectedUser["firstName"],
            username: selectedUser["username"],
            email: selectedUser["email"],
            roleId: selectedUser["roleId"],
            distributorId: selectedUser["distributorId"],
        });
    }

    public handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        var editedUser = { ...this.props.selectedUser };
        editedUser.firstName = this.state.name;
        editedUser.username = this.state.username;
        editedUser.email = this.state.email;
        if (this.state.distributorId) {
            editedUser.distributorId = this.state.distributorId;
        }
        this.props.editUser(editedUser)
        this.setState({
            validated: true
        });
    };

    public onChange = (event) => {
        var validity = event.target.validity;
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    changeCheckbox = (event) => {
        console.log(event.target.value)
    }

    depotsTabClick = (event) => {
        var selectedTab = event.target.innerText;
        //function to change depots based on selected tab
    }

    navigateBack = (event) => {
        this.props.history.push('/users');
    }

    public render() {
        const { isLoading, selectedUser, distributors, depots } = this.props;
        const showDepots = actions.rolesEnum[selectedUser.roleId] === 'TenantSupervisor';
        //TODO check currently logged user for preselected value
        const showDistributors = selectedUser ? selectedUser.roleId === 5 : false;
        return (
            <>
                <Form
                    title='Edit user'
                    hasBox
                    buttonText='Edit'
                    {... { isLoading }}
                    buttonIcon={EditIcon}
                    buttonJustify='space-between'
                    additionalButton={
                        <Button
                            onClick={this.navigateBack}
                            icon={BackIcon}
                            size='large'
                            variant='outlined'
                        >
                            Back
                        </Button>
                    }
                    onSubmit={this.handleSubmit}
                >
                    <TextInput
                        label="Name"
                        name="name"
                        id="name"
                        type="text"
                        required
                        value={this.state.name}
                        onChange={this.onChange}
                    />

                    <TextInput
                        label="Username"
                        name="username"
                        id="userName"
                        required
                        value={this.state.username}
                        onChange={this.onChange}
                    />

                    <TextInput
                        label="Email"
                        name="email"
                        id="email"
                        type="email"
                        required
                        value={this.state.email}
                        onChange={this.onChange}
                    />

                    {showDistributors ? (
                        <Select
                            label="Selected distributor"
                            name="distributorId"
                            value={this.state.distributorId}
                            onChange={this.onChange}
                            options={distributors.map((distributor, id) => {
                                return {
                                    value: id,
                                    label: distributor.name
                                }
                            })}
                        />
                    ) : ''}
                </Form>

                {showDepots ? (
                    <div style={{ marginTop: 30 }}>
                        <div>
                            <h4>Depots</h4>
                                <ul>
                                    {this.state.depotsTabs.map((location, i) => {
                                        return (<Button onClick={this.depotsTabClick}>{location}</Button>)
                                })}
                            </ul>
                        </div>

                    <Box hasContentGutter>
                        <Row>
                            <Col sm={3}>
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                            </Col>
                            <Col sm={3}>
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                            </Col>
                            <Col sm={3}>
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                            </Col>
                            <Col sm={3}>
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                                <Checkbox label="City" value="City" onChange={this.changeCheckbox} />
                            </Col>
                        </Row>
                    </Box>
                </div>
                ) : ''}
            </>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.users,
    UsersStore.actionCreators
)(EditUserForm);

import { checkHttpStatus, parseJSON, DEFAULT_HEADERS } from '../Utils/actions';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import * as endpoints from '../constants/endpoints'

const cookies = new Cookies();

export function loginUser(userCredentials, callback, error) {
    return fetch(endpoints.LOGIN_USER_ENDPOINT, {
        method: 'post',
        headers: { 'Accept': '*/*', 'Content-Type': 'application/json-patch+json', 'Authorization': `Bearer ${cookies.get('rawtoken')}` },
        body: JSON.stringify({ username: userCredentials.username, password: userCredentials.password })
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                var token = response.headers.get('X-JWToken');
                let decoded = jwtDecode(token);
                //set cookie
				let exp = decoded["exp"];
                cookies.set('token', decoded, { path: '/', exp }); //exp
                cookies.set('rawtoken', token, { path: '/', exp }); //exp
                console.log(cookies.get('token'))
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function sendVerificationCode(userCredentials, callback, error) {
    return fetch(endpoints.SEND_VERIFICATION_CODE, {
        method: 'post',
        headers: DEFAULT_HEADERS,
        body: JSON.stringify({ email: userCredentials.email })
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function changePassword(userCredentials, callback, error) {
    return fetch(endpoints.CHANGE_PASSWORD, {
        method: 'post',
        headers: DEFAULT_HEADERS,
        body: JSON.stringify({ email: userCredentials.email, resetPasswordCode: userCredentials.resetPasswordCode, newPassword: userCredentials.newPassword })
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function getUserList( callback, error) {
    return fetch(endpoints.GET_USER_LIST, {
        method: 'get',
        headers: DEFAULT_HEADERS
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                callback(response);
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function editUser(userCredentials, callback, error) {
    var url = endpoints.userEditUrlByRoleId[userCredentials.roleId];
    if (userCredentials.roleId === 1) {
        url += userCredentials.userId;
    }
    return fetch(url, {
        method: 'post',
        headers: DEFAULT_HEADERS,
        body: JSON.stringify(userCredentials)
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
}

export function addUser(userCredentials, callback, error) {
    var url = endpoints.userEditUrlByRoleId[userCredentials.roleId];
    if (userCredentials.roleId !== 1) {
        url += userCredentials.userId;
    }
    return fetch(url, {
        method: 'put',
        headers: DEFAULT_HEADERS,
        body: JSON.stringify(userCredentials)
    })
        .then(checkHttpStatus)
        .then(response => {
            try {
                callback();
            } catch (e) {
                console.log(e)
                error(e);
            }
        })
        .catch(e => {
            console.log(e)
            error(e);
        })
} 
function performGetAction(url, error, callback, data, type) {
    return fetch(url, {
        method: 'get',
        headers: DEFAULT_HEADERS
    })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                data[type] = response;
                callback(data);
            } catch (e) {
                console.log(e)
                error('could not load ' + type);
            }
        })
        .catch(e => {
            console.log(e)
            error();
        })
}
export function attemptEdit(selectedUserId, callback, error) {
    var data = {};
    //load depots only if user is TenantSupervisor
    var depots = (data) => performGetAction(endpoints.GET_DEPOTS, error, callback, data, 'depots');
    var distributors = (data) => performGetAction(endpoints.GET_DISTRIBUTORS, error, data["selectedUser"]["roleId"] === 4 ? depots : callback, data, 'distributors');
    var selectedUser = performGetAction(endpoints.GET_USER + selectedUserId, error, distributors, data, 'selectedUser');
    
} 

export function changeAuthenticated(authenticated, callback, error) {
    try {
        if (!authenticated) {
            cookies.remove('token');
            cookies.remove('rawtoken');
        }
        callback();
    } catch (e) {
        console.log(e)
        error(e);
    }
}

//export function receiveProtectedData(data) {
//    return {
//        type: RECEIVE_PROTECTED_DATA,
//        payload: {
//            data: data
//        }
//    }
//}

//export function fetchProtectedDataRequest() {
//    return {
//        type: FETCH_PROTECTED_DATA_REQUEST
//    }
//}

//export function fetchProtectedData(token) {

//    return (dispatch, state) => {
//        //dispatch(fetchProtectedDataRequest());
//        return fetch('http://localhost:3000/getData/', {
//            credentials: 'include',
//            headers: {
//                'Authorization': `Bearer ${token}`
//            }
//        })
//            .then(checkHttpStatus)
//            .then(parseJSON)
//            .then(response => {
//                //dispatch(receiveProtectedData(response.data));
//            })
//            .catch(error => {
//                if (error.response.status === 401) {
//                    //dispatch(loginUserFailure(error));
//                    //dispatch(pushState(null, '/login'));
//                }
//            })
//    }
//}

import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components'
import { ApplicationState } from '../../store';
import * as CounterStore from '../../store/Counter';
import * as FilterableTable from 'react-filterable-table';
import * as actions from '../Utils/actions'
import Box from '../Box'
import Tag from '../Tag'
import Loader from '../Loader'
import tableStyle from '../../components/constants/tableStyle'

const FilterableTableStyled = styled(FilterableTable)`
	${tableStyle}

	.header-row {
		margin: 15px 10px 0 10px;
		justify-content: flex-end;

		input,
		select {
			color: #495057;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid #e2e5ec;
			font-size: 14px;
			height: 38px;

			&:focus {
				box-shadow: none;
				outline: none;
				border-color: ${({ theme }) => theme.colors.default};
			}
		}

		> div {
			flex: 0 0 25%;
			max-width: 25%;
			padding: 0 10px;
		}

		.record-count {
			position: absolute;
			bottom: 17px;
			left: 25px;
			width: auto;
			padding: 0;

			span {
				background: ${({ theme }) => theme.colors.default};
				color: #fff;
				font-size: 10px;
				padding: 4px 8px;
				border-radius: 2px;
			}
		}

		.clear-filter {
			position: absolute;
			right: 18px;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 20px;
			height: 20px;
			line-height: 1;
			text-align: center;
			cursor: pointer;
		}
	}

	.table-container > div {
		overflow-y: visible;
		overflow-x: auto;
		border-bottom: 1px solid ${({ theme }) => theme.colors.border} !important;
		margin: 0;
	}

	.exact-filters {
		display: none;
	}
`

const TableWrapper = styled.div`
	position: relative;
	min-height: 400px;
`

const TableHead = styled.div`
	margin-bottom: 30px;
	width: 100%;
	display: flex;
`

const TableMessage = styled.div`
	text-align: center;
	padding: 20px 20px 40px 20px;
	font-size: 12px;
	color: ${({ theme }) => theme.colors.dark}
`

class FilterableTableContainer extends React.Component<{ tableProps: any, buttons?: React.ReactNode, tableHead?: React.ReactNode, hasActions?: boolean, isLoading?: boolean }, {}> {
	public render() {
		const { data, namespace, initialSort, bsLength,
			tableName, highlightRow, trClassName, closeModal, useBooleanFields,
			useRoleFields, headerVisible, pageSize } = this.props.tableProps;

		const highlight = (record, index) => {
			let id = record.hermesOrder ? record.hermesOrder.id : record.id,
				idxClass = ' idx=' + id;
			let className = trClassName + idxClass;
			return highlightRow ? record.id === Number(highlightRow) ? 'selected-tr ' + className : className : className
		}

		let fields = this.props.tableProps.fields;

		if (useBooleanFields && useBooleanFields.length > 0) {
			for (let i = 0; i < useBooleanFields.length; i++) {
				let idx = fields.findIndex(element => element.name === useBooleanFields[i]),
					editField = fields[idx];
				editField["render"] = this.boolRender;
			}
		}

		if (useRoleFields && useRoleFields.length > 0) {
			for (let i = 0; i < useRoleFields.length; i++) {
				let idx = fields.findIndex(element => element.name === useRoleFields[i]),
					editField = fields[idx];
				editField["render"] = this.roleRender;
			}
		}

		return (
			<TableWrapper>
				{!this.props.isLoading && (
					<>
					{this.props.tableHead && (
						<TableHead>
							{this.props.tableHead}
						</TableHead>
					)}

					<Box
						title={tableName}
						buttons={this.props.buttons}
					>
						{closeModal ? (
							<a className="close" onClick={closeModal}>
								&times;
							</a>) : ''}
							<FilterableTableStyled
								hasActions={this.props.hasActions}
								namespace={namespace}
								initialSort={initialSort}
								data={data}
								fields={fields}
								noRecordsMessage={<TableMessage>There are no records to display</TableMessage>}
								noFilteredRecordsMessage={<TableMessage>No records match your filters!</TableMessage>}
								headerVisible={headerVisible}
								topPagerVisible={false}
								trClassName={highlight}
								pageSize={pageSize}
					/>
					</Box>
					</>
				)}
				<Loader background='semiLight' isLoading={this.props.isLoading} />
			</TableWrapper>
		)
	}

	boolRender = (props) => {
		return props.value ? "True" : "False";
	}

	roleRender = (props) => {
		if (!isNaN(props.value)) {
			props.value = actions.rolesEnum[props.value];
			props.record.roleId = props.value;
		}
		return <Tag>{props.value}</Tag>;
	}

}
export default connect(
	(state: ApplicationState) => state.counter,
	CounterStore.actionCreators
)(FilterableTableContainer);

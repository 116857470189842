import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as actions from '../components/Actions/uploadDataActions';
import { toast } from 'react-toastify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UploadDataState {
    isLoading: boolean;
    selectedFile: any;
    lastTen: any;
    responseMessage: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface UploadFile {
    type: 'UPLOAD_FILE_ACTION';
    selectedFile: any;
}

interface GetLastTen {
    type: 'GET_LAST_TEN';
    lastTen: any;
}

interface AttemptAction {
    type: 'ATTEMPT_ACTION';
}

interface FinishAction {
    type: 'FINISH_ACTION';
}

interface ErrorAction {
    type: 'ERROR_ACTION';
    responseMessage: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = UploadFile | AttemptAction | GetLastTen |
    FinishAction | ErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    uploadFile: (file, fileName): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && file) {
            let callback = () => { dispatch({ type: 'UPLOAD_FILE_ACTION', selectedFile: file }); toast.success('Success') },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error ? error : 'Error' })
                    if(error) {
                        toast.error(error ? error + ' for file ' + fileName : 'Error for file ' + fileName)
                    }
                 };
            actions.uploadFile(file, callback, error);
        }
        else {
            dispatch({ type: 'ERROR_ACTION', responseMessage: 'Error uploading file.' });
            toast.error('Error uploading file.')
        }
    },
    getLastTen: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let callback = (response) => { dispatch({ type: 'GET_LAST_TEN', lastTen: response }) },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error ? error : 'Error' })
                    if (error) {
                        toast.error(error ? error : 'Error')
                    }
                };
            actions.getLastTen(callback, error);
        }
    },
    setErrorMessage: (errorMessage): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: 'ERROR_ACTION', responseMessage: errorMessage });
            if(errorMessage) {
                toast.error(errorMessage)
            }
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UploadDataState = {
    isLoading: false,
    selectedFile: {},
    lastTen: [],
    responseMessage: ''
};

export const reducer: Reducer<UploadDataState> = (state: UploadDataState | undefined, incomingAction: Action): UploadDataState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ATTEMPT_ACTION':
            return {
                ...state,
                isLoading: true,
                responseMessage: ''
            };
            break;
        case 'UPLOAD_FILE_ACTION':
            return {
                ...state,
                isLoading: false,
                selectedFile: action.selectedFile,
                responseMessage: 'Successfully uploaded file.'
            };
            break;
        case 'GET_LAST_TEN':
            return {
                ...state,
                isLoading: false,
                lastTen: action.lastTen
            };
            break;
        case 'FINISH_ACTION':
            return {
                ...state,
                isLoading: false
            };
            break;
        case 'ERROR_ACTION':
            return {
                ...state,
                responseMessage: action.responseMessage,
                isLoading: false
            }
            break;
    }

    return state;
};

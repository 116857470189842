import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const Sizes = {
  large: css`
    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        font-size: 18px;
        margin-bottom: 20px;
      }

      @media ${theme.mediaQueries.smUp} {
        font-size: 20px;
        margin-bottom: 24px;
      }
    `}
  `,
  small: css`
    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        font-size: 15px;
        margin-bottom: 15px;
      }

      @media ${theme.mediaQueries.smUp} {
        font-size: 18px;
        margin-bottom: 18px;
      }
    `}
  `
}

const Heading = styled.h1`
  white-space: pre-line;
  color: ${({ theme, color })=> theme.colors[color]};
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.5px;

  ${({ size })=> Sizes[size]}

  ${({ disableMargin })=> disableMargin && `
    margin: 0 !important;
  `};
`

interface Props {
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
    color?: 'light' | 'dark',
    size?: 'small' | 'large',
    disableMargin?: boolean,
    children: any,
  }

const HeadlineComponent: FunctionComponent<Props> = ({ as = 'h1', color = 'dark', size = 'small', disableMargin = false, children }) => (
    <Heading {...{
      color,
      size,
      as,
      disableMargin,
    }}>
      {children}
    </Heading>
  )

export default HeadlineComponent

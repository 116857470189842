import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { ApplicationState, reducers } from "./index"; // Import your root reducer or individual reducers

// Create the root reducer
const rootReducer = combineReducers({
  ...reducers, // Spread in your reducers
  // other reducers can be added here
});

// Configure the store
const configureStore = (initialState?: ApplicationState) => {
  const middleware = [thunk];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );
};

// Create the store with optional initial state
export const store = configureStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Export the configured store

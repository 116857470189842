import React, { useEffect, FC } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store";
import * as ReceivedSalesFilesStore from "../../store/ReceivedSalesFilesReducer";
import Select from "../Select";
import Cookies from "universal-cookie";
import { getGridDefaultProps } from "constants/agGridConstants";
import { AgGridReact } from "ag-grid-react";
import useReceivedSalesFilesColDefs from "components/Reports/useReceivedSalesFilesColDefs";

const cookies = new Cookies();
type Props = {
  getDistributorDepotsByDistributorId: (distributorId: number) => void;
  setDepot: (depotId: number) => void;
  downloadReceivedSalesFileReport: (reportId: number) => void;
  depotsList: any[];
  selectedDepot: any;
  salesFilesList: any[];
};
const ReceivedSalesFiles: FC<Props> = ({
  getDistributorDepotsByDistributorId,
  setDepot,
  downloadReceivedSalesFileReport,
  depotsList,
  selectedDepot,
  salesFilesList,
}) => {
  useEffect(() => {
    const token = cookies.get("token");
    const distributorId = token
      ? token["DistributorId"]
        ? token["DistributorId"]
        : 0
      : 0;
    getDistributorDepotsByDistributorId(distributorId);
  }, []);

  const onChange = (event) => {
    event.preventDefault();
    if (event.target.name === "selectedDepot") {
      const depotId = event.target.value;
      setDepot(depotId);
    }
  };

  const { columnDefs } = useReceivedSalesFilesColDefs({
    downloadReceivedSalesFileReport,
  });

  return (
    <div style={{ height: "100%" }}>
      <div style={{ width: "fit-content", marginLeft: "auto" }}>
        <Select
          label="Depot"
          name="selectedDepot"
          value={selectedDepot}
          defaultValue={"-1"}
          onChange={onChange}
          options={
            depotsList
              ? depotsList.map((depot) => ({
                  value: depot.id,
                  label: depot.depotName,
                }))
              : []
          }
        />
      </div>
      <AgGridReact
        rowHeight={30}
        getRowId={(params) => params.data.id}
        rowData={salesFilesList}
        columnDefs={columnDefs}
        {...getGridDefaultProps()}
      />
    </div>
  );
};

export default connect(
  (state: ApplicationState) => state.receivedSalesFiles,
  ReceivedSalesFilesStore.actionCreators,
)(ReceivedSalesFiles);

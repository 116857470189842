import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import Button from './Button'
import { ValidationForm } from '../react-bootstrap4-form-validation-modified/lib';
import Loader from './Loader'
import Box from './Box'

const FormStyled = styled(ValidationForm)`
  position: relative;
  padding: ${({ hasBox })=> hasBox ? '0' : '0 30px'};
`

const BoxForm = styled.div`
  padding: 30px;
`

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${({ hasBox })=> hasBox ? '30px' : '10px'};
  justify-content: ${({ justifyContent })=> justifyContent};
`

interface Props {
    title?: string,
    onSubmit: any,
    children: ReactNode,
    isLoading?: boolean,
    buttonSize?: 'small' | 'large' | 'extraLarge',
    buttonIcon?: any,
    buttonJustify?: 'flex-start' | 'center' | 'flex-end' | 'space-between',
    buttonText: string,
    hasBox?: boolean,
    additionalButton?: ReactNode
  }

const FormComponent: FunctionComponent<Props> = ({
  title,
  onSubmit,
  children,
  isLoading,
  buttonSize = 'large',
  buttonJustify = 'flex-start',
  buttonIcon,
  buttonText,
  hasBox = false,
  additionalButton
}) => {

  const FormButtons = ()=> (
    <ButtonWrapper {... { hasBox }} justifyContent={buttonJustify}>
      {additionalButton}
      <Button size={buttonSize} icon={buttonIcon} type="submit">{buttonText}</Button>
    </ButtonWrapper>
  )

  return (
    <FormStyled {... { onSubmit, hasBox }}>
      {hasBox ? (
        <>
          <Box {... { title }}>
            <BoxForm>
              {children}
              <Loader {... { isLoading }} />
            </BoxForm>
          </Box>
          <FormButtons />
        </>
      ) : (
        <>
            {children}
            <Loader {... { isLoading }} />
            <FormButtons />
        </>
      )}
    </FormStyled>
  )
}

export default FormComponent

import * as React from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import FetchData from "./components/FetchData";
import LoginForm from "./components/Users/LoginForm";
import PasswordRecoveryForm from "./components/Users/PasswordRecoveryForm";
import UsersList from "./components/Users/UsersList";
import EditUserForm from "./components/Users/EditUserForm";
import ProtectedRoute from "./helpers/ProtectedRoute";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import Orders from "./components/Orders/Orders";
import OrderDetails from "./components/Orders/OrderDetails";
import Dashboard from "./components/Dashboard/DistributorDashboard";
import ReceivedSalesFiles from "./components/Reports/ReceivedSalesFiles";
import UploadData from "./components/UploadData/UploadData";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CreateOrder from "components/MpOrder/CreateOrder/CreateOrder";
import OrderHistory from "components/MpOrder/OrderHistory/OrderHistory";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const App: React.FC = () => {
  const { t } = useTranslation(); //needed for the translations change to work

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/passwordRecovery" element={<PasswordRecoveryForm />} />
        <Route path="/fetch-data/:startDateIndex?" element={<FetchData />} />
        <Route
          path="/edit-user/:userId?"
          element={
            <ProtectedRoute isAuthenticated={true}>
              <EditUserForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute isAuthenticated={true}>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-history"
          element={
            <ProtectedRoute isAuthenticated={true}>
              <OrderHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-order"
          element={
            <ProtectedRoute isAuthenticated={true}>
              <CreateOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order/:orderId?"
          element={
            <ProtectedRoute isAuthenticated={true}>
              <OrderDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute isAuthenticated={true}>
              <UsersList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAuthenticated={true}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sent-data"
          element={
            <ProtectedRoute isAuthenticated={true}>
              <ReceivedSalesFiles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-data"
          element={
            <ProtectedRoute isAuthenticated={true}>
              <UploadData />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Layout>
  );
};
export default App;

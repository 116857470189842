import Cookies from "universal-cookie";

const cookies = new Cookies();

export var DEFAULT_HEADERS = {
  Accept: "*/*",
  "Content-Type": "application/json-patch+json",
  Authorization: `Bearer ${cookies.get("rawtoken")}`,
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return date ? [year, month, day].join("-") : "-";
};

export const dateRender = (props) => {
  var date = props.value;
  return date ? formatDate(date) : date;
};

export var rolesEnum = {
  1: "Administrator",
  2: "TenantAdmin",
  3: "TenantSupport",
  4: "TenantSupervisor",
  5: "Distributor",
};
export const isTokenValid = () => {
  const token = cookies.get("token");
  if (!token) return false;
  const exp = token["exp"];
  const currentTime = new Date().getTime();
  if (!exp) return false;
  return exp * 1000 > currentTime;
};
export function getUserRole() {
  var token = cookies.get("token");
  if (token) {
    return token[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];
  }
}

export function getUserId() {
  var token = cookies.get("token");
  if (token) {
    return token["UserId"];
  }
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    throw response;
  }
}

export function parseJSON(response) {
  try {
    var res = response.json();
    return res;
  } catch (e) {
    return response;
  }
}

export function parseText(response) {
  try {
    var res = response.text();
    return res;
  } catch (e) {
    return response;
  }
}

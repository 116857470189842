import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { toast } from 'react-toastify'
import * as actions from '../components/Actions/pocActions';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface POCState
{
    isLoading: boolean;
    createdPocMapping: any;
    distributorCustomers: any;
    kamenitzaCustomers: any;
    pocMappings: any;
    responseMessage: string;
    mappingHistoryData: any;
    shouldRender: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetPocMappingsAction {
    type: 'GET_POC_MAPPINGS_ACTION';
    pocMappings: any;
}

interface GetDistributorCustomersAction
{
    type: 'GET_DISTRIBUTOR_CUSTOMERS_ACTION';
    distributorCustomers: any;
}

interface GetKamenitzaCustomersAction {
    type: 'GET_KAMENITZA_CUSTOMERS_ACTION';
    kamenitzaCustomers: any;
}

interface CreatepocMappingAction
{
    type: 'CREATE_POC_MAPPING_ACTION';
    createdPocMapping: any;
    responseMessage: string;
}

interface AttemptAction
{
    type: 'ATTEMPT_ACTION';
}

interface FinishAction {
    type: 'FINISH_ACTION';
}

interface ErrorAction {
    type: 'ERROR_ACTION';
    responseMessage: string;
}

interface SetMappingHistory {
    type: 'SET_MAPPING_HISTORY';
    mappingHistoryData: any[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetPocMappingsAction | GetDistributorCustomersAction | GetKamenitzaCustomersAction |
    CreatepocMappingAction | AttemptAction | FinishAction | ErrorAction | SetMappingHistory;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getDistributorCustomers: (cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (distributorCustomers) => { dispatch({ type: 'GET_DISTRIBUTOR_CUSTOMERS_ACTION', distributorCustomers: distributorCustomers }); cb(distributorCustomers); },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.getCustomers('distributors', callback, error);
        }
    },
    getKamenitzaCustomers: (cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (kamenitzaCustomers) => { dispatch({ type: 'GET_KAMENITZA_CUSTOMERS_ACTION', kamenitzaCustomers: kamenitzaCustomers }); cb(kamenitzaCustomers); },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.getCustomers('hermes', callback, error);
        }
    },
    getPocMappings: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        //TODO URL
        const method = 'get';
        dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState) {
            let callback = (pocMappings) => { dispatch({ type: 'GET_POC_MAPPINGS_ACTION', pocMappings: pocMappings }) },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.pocAction(method, callback, error);
        }
    },
    //TODO update?
    pocMappingAction: (pocMapping, action, cb): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        var method = '';
        switch (action) {
            case "create":
                method = "post";
                break;
            case "update":
                method = "put";
                break;
            case "delete":
                method = "delete";
                break;
        }
        //dispatch({ type: 'ATTEMPT_ACTION' })
        const appState = getState();
        if (appState && pocMapping) {
            let callback = (pocMapping) => {
                dispatch({ type: 'CREATE_POC_MAPPING_ACTION', createdPocMapping: pocMapping, responseMessage: 'Success' }); if (cb) { cb(pocMapping.id); } },
                error = (error) => {
                    dispatch({ type: 'ERROR_ACTION', responseMessage: error.response ? error.response.statusText : 'Error' })
                    toast.error(error.response ? error.response.statusText : 'Error')
                };
            actions.pocAction(method, callback, error, pocMapping);
        }
    },
    setMappingHistory: (mappingHistoryData: any[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        dispatch({ type: 'SET_MAPPING_HISTORY', mappingHistoryData: mappingHistoryData });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: POCState = {
    isLoading: false,
    createdPocMapping: {},
    distributorCustomers: [],
    kamenitzaCustomers: [],
    pocMappings: [],
    responseMessage: '',
    mappingHistoryData: [],
    shouldRender: true
};

export const reducer: Reducer<POCState> = (state: POCState | undefined, incomingAction: Action): POCState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ATTEMPT_ACTION':
            return {
                ...state,
                isLoading: true,
                responseMessage: ''
            };
            break;
        case 'FINISH_ACTION':
            return {
                ...state,
                isLoading: false
            };
            break;
        case 'GET_POC_MAPPINGS_ACTION':
            return {
                ...state,
                pocMappings: action.pocMappings,
                isLoading: false
            };
            break;
        case 'GET_DISTRIBUTOR_CUSTOMERS_ACTION':
                return {
                    ...state,
                    distributorCustomers: action.distributorCustomers,
                    isLoading: false,
                    shouldRender: true
                };
            break;
        case 'GET_KAMENITZA_CUSTOMERS_ACTION':
            return {
                ...state,
                kamenitzaCustomers: action.kamenitzaCustomers,
                isLoading: false,
                shouldRender: true
            };
            break;
        case 'CREATE_POC_MAPPING_ACTION':
            return {
                ...state,
                createdPocMapping: action.createdPocMapping,
                responseMessage: action.responseMessage,
                isLoading: false,
                shouldRender: false
            };
            break;
        case 'ERROR_ACTION':
            return {
                ...state,
                responseMessage: action.responseMessage,
                isLoading: false
            }
            break;
        case 'SET_MAPPING_HISTORY':
            return {
                ...state,
                mappingHistoryData: action.mappingHistoryData
            }
            break;
    }

    return state;
};

import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as actions from "../components/Utils/actions";
import { isTokenValid } from "../components/Utils/actions";
import * as AuthenticationStore from "../store/AuthenticationReducer";
import { useDispatch } from "react-redux";

type ProtectedRouteProps = {
  children: React.ReactNode;
  isAuthenticated: boolean;
  role?: string;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  isAuthenticated,
  role,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = actions.getUserRole();
  const dispatch = useDispatch();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if ((role && userRole !== role) || !isTokenValid()) {
    dispatch(AuthenticationStore.actionCreators.changeAuthentication(false));
    navigate("/");
    return <p>You cannot access this route.</p>;
  }

  return <>{children}</>;
};

export default ProtectedRoute;

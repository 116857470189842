import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as AuthenticationStore from "../../store/AuthenticationReducer";
import Form from "../Form";
import TextInput from "../TextInput";
import AuthenticationScreen from "../AuthenticationScreen";
import withRouter, { WithRouterHocProps } from "../../helpers/WithRouterHOC";

type AuthenticationProps = AuthenticationStore.AuthenticationState &
  typeof AuthenticationStore.actionCreators;

class PasswordRecoveryForm extends React.PureComponent<
  AuthenticationProps & WithRouterHocProps
> {
  constructor(props) {
    super(props);
  }

  public state = {
    email: "",
    password: "",
    confirmPassword: "",
    verificationCode: "",
    validUser: false,
    validated: false,
    response: "",
  };

  handleSubmit = (event) => {
    // POST validated form and show response message
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      return false;
    }

    var userCredentials = {
      email: this.state.email,
      resetPasswordCode: this.state.verificationCode,
      newPassword: this.state.password,
    };
    this.props.attemptChangePassword(userCredentials);

    event.preventDefault();
    event.stopPropagation();
  };

  sendVerificationCode = (event) => {
    //email exists in database
    this.props.attemptSendVerificationCode({ email: this.state.email });
    event.preventDefault();
  };

  onChange = (event) => {
    var validity = event.target.validity;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  matchPassword = (value) => {
    return value && value === this.state.password;
  };

  public render() {
    const { isValidEmail, isLoading } = this.props;
    return (
      <AuthenticationScreen additionalLink={{ to: "/", label: "Go to Login" }}>
        {!isValidEmail ? (
          <Form
            {...{ isLoading }}
            buttonText="Send verification code"
            buttonSize="extraLarge"
            buttonJustify="center"
            onSubmit={this.sendVerificationCode}
          >
            <TextInput
              label="Email"
              name="email"
              id="email"
              size="large"
              required
              //validator={(value) => value.trim().length !== 0} or e.g. use validator.isEmail
              //errorMessage={{ validator: "Please enter a valid email" }}
              value={this.state.email}
              onChange={this.onChange}
            />
          </Form>
        ) : (
          <Form
            {...{ isLoading }}
            buttonText="Reset password"
            buttonSize="extraLarge"
            buttonJustify="center"
            onSubmit={this.handleSubmit}
          >
            <TextInput
              label="Verification code"
              name="verificationCode"
              id="verificationCode"
              size="large"
              required
              minLength={6}
              maxLength={6}
              value={this.state.verificationCode}
              onChange={this.onChange}
            />

            <TextInput
              label="Enter new password"
              name="password"
              id="password"
              type="password"
              size="large"
              required
              pattern="(?=.*[A-Z]).{6,}"
              errorMessage={{
                required: "Password is required",
                pattern:
                  "Password should be at least 6 characters and contains at least one upper case letter",
              }}
              value={this.state.password}
              onChange={this.onChange}
            />

            <TextInput
              label="Confirm new password"
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              size="large"
              required
              validator={this.matchPassword}
              errorMessage={{
                required: "Confirm password is required",
                validator: "Password does not match",
              }}
              value={this.state.confirmPassword}
              onChange={this.onChange}
            />
          </Form>
        )}
      </AuthenticationScreen>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => state.authentication,
    AuthenticationStore.actionCreators,
  )(PasswordRecoveryForm),
);

import React, { useMemo } from "react";

import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Col, Image, Row } from "antd";
import dayjs from "dayjs";
import i18next from "i18next";

import {
  BSCoreServicesDtoMarketplaceOrderProductDto,
  BSDALMarketplaceEnumsOrderPackingType,
} from "api/reactQuery/querySchemas";
import {
  MarketplaceOrderPackingTypes,
  MarketplacePalletsTypes,
} from "components/Utils/enums";
import { COLUMN_TYPE_NAMES } from "constants/agGridConstants";
import { currencyBGNFormatter } from "components/Utils/agGrid/valueFormatters";

const ProductNameCombinedCellRenderer = (
  params: ICellRendererParams<BSCoreServicesDtoMarketplaceOrderProductDto>,
) => (
  <Row gutter={8} style={{ height: "100%", width: "100%" }}>
    <Col
      span={8}
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {params.data.productImgUrl && (
        <Image
          alt="Product img"
          width="auto"
          height="100%"
          src={params.data.productImgUrl}
        />
      )}
    </Col>
    <Col
      span={16}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        lineHeight: "normal",
      }}
    >
      <span style={{ color: "#363636" }}>{params.value}</span>
      <div
        style={{
          color: "#6D6D6D",
          display: "flex",
          flexDirection: "column",
          fontSize: 10,
          marginTop: 2,
        }}
      >
        <span>
          {MarketplaceOrderPackingTypes[params.data.orderPackingType] ===
          BSDALMarketplaceEnumsOrderPackingType.Pallet
            ? i18next.t("product_order_pallet")
            : i18next.t("product_order_count", {
                productUnitsInPallet: params.data.productUnitsInPallet,
              })}
        </span>
        <span>{params.data.importComment}</span>
        <span>
          {params.data.availabilityDate &&
            i18next.t("additional_stock_available_at", {
              availabilityDate: dayjs(params.data.availabilityDate)?.format(
                "DD/MM/YYYY",
              ),
            })}
        </span>
      </div>
    </Col>
  </Row>
);

const useColDefs = (props: {
  showCurrentAvailableUnits: boolean;
  enableEditOrderedQuantities: boolean;
  showOnInvoiceQuantityLimit?: boolean;
  showMultiPackQuantityLimit?: boolean;
}) => {
  const columnDefs = useMemo<
    ColDef<BSCoreServicesDtoMarketplaceOrderProductDto>[]
  >(
    () => [
      {
        cellRenderer: ProductNameCombinedCellRenderer,
        cellStyle: { padding: 0 },
        field: "productName",
        headerName: i18next.t("product_name"),
        minWidth: 380,
      },
      {
        field: "expiryDate",
        headerName: i18next.t("expiry_date"),
        maxWidth: 90,
        type: COLUMN_TYPE_NAMES.DATE_COLUMN,
      },
      { field: "productPrice", headerName: i18next.t("product_price") },
      {
        field: "productUnitsInPallet",
        headerName: i18next.t("units_in_pallet"),
        width: 70,
      },
      {
        field: "pallet.type",
        headerName: i18next.t("pallet_type"),
        valueFormatter: (params) =>
          MarketplacePalletsTypes[params.value] || params.value,
      },

      {
        field: "onInvoiceQuantityLimit",
        headerName: i18next.t("on_invoice_quantity_limit"),
        hide: !props.showOnInvoiceQuantityLimit,
      },
      {
        field: "multiPackQuantityLimit",
        headerName: i18next.t("multipack_quantity_limit"),
        hide: !props.showMultiPackQuantityLimit,
      },
      {
        field: "productVolumeInHL",
        headerName: i18next.t("product_volume_in_hl"),
      },
      { field: "productWeight", headerName: i18next.t("product_weight") },
      {
        cellStyle: {
          backgroundColor: "#F1F7FF",
          opacity: props.enableEditOrderedQuantities ? 1 : 0.5,
        },
        editable: props.enableEditOrderedQuantities,
        field: "orderedQuantities",
        headerClass: "ordered-quantities-header",
        headerName: i18next.t("ordered_quantities"),
        tooltipValueGetter: (params) =>
          props.enableEditOrderedQuantities
            ? params.value
            : i18next.t("select_delivery_date"),
        width: 70,
      },
      {
        cellStyle: {
          backgroundColor: "#FEFFD4",
        },
        field: "currentAvailableUnits",
        headerClass: "current-available-units-header",
        headerName: i18next.t("current_availability"),
        hide: !props.showCurrentAvailableUnits,
        width: 70,
      },
      { field: "orderedUnits", headerName: i18next.t("ordered_units") },
      {
        field: "weightOfOrderUnit",
        headerName: i18next.t("order_unit_weight"),
        width: 70,
      },
      {
        field: "totalBeerCostWithoutVatInBGN",
        headerName: i18next.t("total_beer_cost_without_vat_in_bgn"),
      },
      {
        field: "totalPackagingCostInBGN",
        headerName: i18next.t("total_packaging_cost_in_bgn"),
      },
      {
        field: "totalBeerAndPackagingCostWithVat",
        headerName: i18next.t("total_beer_and_packaging_cost_with_vat"),
        valueFormatter: currencyBGNFormatter,
        width: 100,
      },

      {
        field: "productSortIndex",
        headerName: i18next.t("product_sort_index"),
        hide: true,
        sort: "asc",
      },
    ],
    [
      props.showOnInvoiceQuantityLimit,
      props.showMultiPackQuantityLimit,
      props.enableEditOrderedQuantities,
      props.showCurrentAvailableUnits,
    ],
  );

  return { columnDefs };
};

export default useColDefs;

import React from "react";

export default () => (
  <svg
    width="208"
    height="208"
    viewBox="0 0 208 208"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="207.688"
      height="207.688"
      transform="translate(0.15625 0.15625)"
      fill="white"
    />
    <path
      d="M37.0996 78.6562V51.1562H178.1V158.156H37.0996V134.656"
      stroke="#4D5152"
      strokeWidth="8"
    />
    <path
      d="M49.1562 75.1562L106.656 99.1562L164.156 75.1562V147.156H49.1562V75.1562Z"
      fill="#CBFFDA"
    />
    <path d="M54.2362 103.127H27.3828V111.24H54.2362V103.127Z" fill="#4D5152" />
    <path d="M19.2691 103.127H11.1562V111.24H19.2691V103.127Z" fill="#4D5152" />
    <path
      d="M76.2616 86.9014H49.4082V95.0142H76.2616V86.9014Z"
      fill="#4D5152"
    />
    <path
      d="M41.2944 86.9014H33.1816V95.0142H41.2944V86.9014Z"
      fill="#4D5152"
    />
    <path
      d="M76.2616 119.353H49.4082V127.466H76.2616V119.353Z"
      fill="#4D5152"
    />
    <path
      d="M41.2944 119.353H33.1816V127.466H41.2944V119.353Z"
      fill="#4D5152"
    />
    <path
      d="M35.6562 53.6562L106.656 84.6562L178.656 57.1562"
      stroke="#4D5152"
      strokeWidth="8"
    />
    <g filter="url(#filter0_d_250_126705)">
      <circle cx="48.6562" cy="155.656" r="28.5" fill="#F5F3F3" />
    </g>
    <path
      d="M63.1377 150.277C64.697 148.429 64.4369 145.689 62.5566 144.157C60.6763 142.625 57.888 142.881 56.3287 144.728L44.7368 158.464L41.1396 153.669C39.6902 151.736 36.9213 151.325 34.9551 152.749C32.9888 154.173 32.5699 156.894 34.0193 158.826L37.6165 163.622C41.0273 168.168 47.8763 168.361 51.5458 164.013L63.1377 150.277Z"
      fill="#449E5D"
    />
    <defs>
      <filter
        id="filter0_d_250_126705"
        x="17.1562"
        y="127.156"
        width="65"
        height="65"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_250_126705"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_250_126705"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// Define the props that withRouter will inject
export interface WithRouterHocProps {
  navigate: any;
  location: any;
  params: Readonly<any>;
}

function withRouter<T>(Component: React.ComponentType<T & WithRouterHocProps>) {
  return (props: T) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    // Pass the props to the wrapped component
    return (
      <Component
        {...(props as T)}
        navigate={navigate}
        location={location}
        params={params}
      />
    );
  };
}

export default withRouter;

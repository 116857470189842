import React, { useEffect, useRef, useState } from "react";

import { ExportOutlined } from "@ant-design/icons";
import { GetRowIdParams, RowSelectedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, DatePicker, Flex, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import i18next from "i18next";

import {
  useDownloadMpOrdersHistoryPerDepot,
  useGetAllRealOrders,
} from "api/reactQuery/queryComponents";
import {
  BSCoreServicesDtoMarketplaceOrderDto,
  BSCoreServicesDtoMarketplaceOrderProductDto,
} from "api/reactQuery/querySchemas";
import useColDefs from "components/MpOrder/OrderHistory/useColDefs";
import OrderTotals from "components/MpOrder/OrderTotals";
import { dateFormat, dateFormatForBE } from "components/Utils/dayjsUtils";
import { getGridDefaultProps } from "constants/agGridConstants";

const downloadBlob = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

const OrderHistory: React.FC = () => {
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>(dayjs);
  const [selectedOrder, setSelectedOrder] =
    useState<BSCoreServicesDtoMarketplaceOrderDto>();
  const gridRef =
    useRef<AgGridReact<BSCoreServicesDtoMarketplaceOrderDto>>(null);
  const { data: orders } = useGetAllRealOrders(
    {
      queryParams: {
        endDate: endDate ? endDate?.format(dateFormatForBE) : "",
        startDate: startDate ? startDate?.format(dateFormatForBE) : "",
      },
    },
    { enabled: !!startDate || !!endDate },
  );
  const { mutate: downloadOrderHistory, isLoading: downloadLoading } =
    useDownloadMpOrdersHistoryPerDepot({
      onSuccess: (data) =>
        downloadBlob(data as Blob, `Order_${selectedOrder.id}.pdf`),
    });

  const { orderColumnDefs, productColumnDefs } = useColDefs();

  const orderData = {
    onInvoiceProducts: selectedOrder?.products?.filter(
      (product) => product.isInvoiceProduct,
    ),
    multiPackProducts: selectedOrder?.products?.filter(
      (product) => product.isMultiPack,
    ),
    products: selectedOrder?.products?.filter(
      (product) => !product.isInvoiceProduct && !product.isMultiPack,
    ),
    total: selectedOrder?.total,
  };
  const onRowSelected = (
    event: RowSelectedEvent<BSCoreServicesDtoMarketplaceOrderDto>,
  ) => event?.event && setSelectedOrder(event.data);

  const onDateChangeHandler = async (dates: Dayjs[]) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    }
    setSelectedOrder(undefined);
  };

  const updatePinnedBottomRowData = () => {
    const gridApi = gridRef.current?.api;
    if (!gridApi) return;

    const allRowData = [];
    gridApi.forEachNodeAfterFilterAndSort((node) => allRowData.push(node.data));

    const calculateTotal = (field: string) =>
      allRowData.reduce((sum, row) => sum + (row.total?.[field] || 0), 0);

    const totalTtlVolumeInHL = calculateTotal("ttlVolumeInHL");
    const totalTtlPackagingCostInBGN = calculateTotal("ttlPackagingCostInBGN");

    const pinnedBottomRowData = [
      {
        total: {
          ttlPackagingCostInBGN: totalTtlPackagingCostInBGN,
          ttlVolumeInHL: totalTtlVolumeInHL,
        },
      },
    ];

    gridApi.setGridOption("pinnedBottomRowData", pinnedBottomRowData);
  };

  useEffect(() => {
    const currentGridRefApi = gridRef.current?.api;
    if (currentGridRefApi) {
      currentGridRefApi.addEventListener(
        "filterChanged",
        updatePinnedBottomRowData,
      );
      updatePinnedBottomRowData();
    }

    return () => {
      if (currentGridRefApi) {
        currentGridRefApi.removeEventListener(
          "filterChanged",
          updatePinnedBottomRowData,
        );
      }
    };
  }, [orders]);

  return (
    <>
      <Typography.Title level={3} style={{ marginBottom: 20 }}>
        {i18next.t("history_of_orders")}
      </Typography.Title>
      <Flex align="center" justify="space-between">
        <div>
          <Typography.Text style={{ marginRight: 10 }}>
            {i18next.t("select_period")}
          </Typography.Text>
          <DatePicker.RangePicker
            format={dateFormat}
            maxDate={dayjs()}
            defaultValue={[startDate, endDate]}
            onChange={onDateChangeHandler}
          />
        </div>
        <Button
          style={{ padding: "0 50px" }}
          type="primary"
          loading={downloadLoading}
          onClick={() => downloadOrderHistory({ body: selectedOrder })}
          disabled={!selectedOrder}
        >
          <ExportOutlined style={{ verticalAlign: "middle" }} />
          {i18next.t("export_in_pdf")}
        </Button>
      </Flex>
      <div style={{ height: 200 }}>
        <AgGridReact
          getRowId={(
            params: GetRowIdParams<BSCoreServicesDtoMarketplaceOrderDto>,
          ) => String(params.data.id)}
          rowData={orders}
          ref={gridRef}
          onGridReady={updatePinnedBottomRowData}
          columnDefs={orderColumnDefs}
          rowSelection="single"
          onRowSelected={onRowSelected}
          {...getGridDefaultProps()}
        />
      </div>

      {selectedOrder ? (
        <Flex style={{ margin: "20px 0 10px", width: "100%" }}>
          {!!orderData?.total && (
            <OrderTotals totals={orderData.total || orderData.total} />
          )}
        </Flex>
      ) : (
        !selectedOrder && (
          <Typography.Title
            level={1}
            style={{
              color: "#6D6D6D",
              marginTop: 150,
              textAlign: "center",
            }}
          >
            {i18next.t("please_select_order_row_from_the_table")}
          </Typography.Title>
        )
      )}
      {!!orderData?.multiPackProducts?.length && (
        <>
          <Typography.Title level={3}>
            {i18next.t("multipack_products")}
          </Typography.Title>
          <div style={{ height: 220, margin: "10px 0" }}>
            <AgGridReact
              getRowId={(
                params: GetRowIdParams<BSCoreServicesDtoMarketplaceOrderProductDto>,
              ) => String(params.data.id)}
              rowData={orderData.multiPackProducts}
              columnDefs={productColumnDefs}
              {...getGridDefaultProps()}
            />
          </div>
        </>
      )}
      {!!orderData?.onInvoiceProducts?.length && (
        <>
          <Typography.Title level={3}>
            {i18next.t("on_invoice_products")}
          </Typography.Title>
          <div style={{ height: 220, margin: "10px 0" }}>
            <AgGridReact
              getRowId={(
                params: GetRowIdParams<BSCoreServicesDtoMarketplaceOrderProductDto>,
              ) => String(params.data.id)}
              rowData={orderData.onInvoiceProducts}
              columnDefs={productColumnDefs}
              {...getGridDefaultProps()}
            />
          </div>
        </>
      )}
      {!!orderData?.products?.length && (
        <>
          <Typography.Title level={3}>{i18next.t("products")}</Typography.Title>
          <div style={{ height: 400 }}>
            <AgGridReact
              getRowId={(
                params: GetRowIdParams<BSCoreServicesDtoMarketplaceOrderProductDto>,
              ) => String(params.data.id)}
              rowData={orderData.products}
              columnDefs={productColumnDefs}
              {...getGridDefaultProps()}
            />
          </div>
        </>
      )}
    </>
  );
};
export default OrderHistory;

import {
  BSDALMarketplaceEnumsOrderPackingType,
  BSDALMarketplaceEnumsOrderStatus,
  BSDALMarketplaceEnumsPackagingType,
  BSDALMarketplaceEnumsPalletGroupType,
  BSDALMarketplaceEnumsPalletType,
} from "api/reactQuery/querySchemas";

const createNumericEnum = <T extends Record<string, string>>(enumObj: T) => {
  const numericEnum: { [key: number]: T[keyof T] } = {};
  let index = 0;

  for (const key in enumObj) {
    if (enumObj.hasOwnProperty(key)) {
      numericEnum[index] = enumObj[key];
      index++;
    }
  }

  return numericEnum;
};

export const MarketplaceOrderStatuses = createNumericEnum(
  BSDALMarketplaceEnumsOrderStatus,
);
export const MarketplacePalletsTypes = createNumericEnum(
  BSDALMarketplaceEnumsPalletType,
);
export const MarketplacePalletsGroupTypes = createNumericEnum(
  BSDALMarketplaceEnumsPalletGroupType,
);
export const MarketplacePackagingTypes = createNumericEnum(
  BSDALMarketplaceEnumsPackagingType,
);
export const MarketplaceOrderPackingTypes = createNumericEnum(
  BSDALMarketplaceEnumsOrderPackingType,
);

import { ColDef } from "ag-grid-community";
import { AgGridReactProps } from "ag-grid-react";
import dayjs from "dayjs";
import { dateFormatter } from "components/Utils/agGrid/valueFormatters";

export const CENTERED_HEADER_CLASS = "centered-columns";
export const CENTERED_CELL_STYLE = { textAlign: "center" };
export const CENTERED_COL_DEF = {
  cellStyle: CENTERED_CELL_STYLE,
  headerClass: CENTERED_HEADER_CLASS,
};

export const COLUMN_TYPES = {
  ACTION_COLUMN: {
    cellStyle: { textAlign: "left" },
  },
  BOOLEAN_COLUMN: {
    cellStyle: CENTERED_CELL_STYLE,
  },
  DATE_COLUMN: {
    filter: "agDateColumnFilter",
    filterParams: {
      browserDatePicker: true,
      clearButton: true,
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        // Parse the cell value to a date
        const cellDate = dayjs(cellValue).toDate();
        // Compare dates
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      debounceMs: 500,
      defaultOption: "equals",
      inRangeInclusive: true,
      maxValidYear: 2099,
      minValidYear: 2000,
      newRowsAction: "keep",
      suppressAndOrCondition: true,
    },

    valueFormatter: dateFormatter,
  },
  NUMBER_COLUMN: {
    cellStyle: CENTERED_CELL_STYLE,
    filter: "agNumberColumnFilter",
  },
  TEXT_COLUMN: {
    cellStyle: { textAlign: "left" },
    filter: "agTextColumnFilter",
  },
};
export const COLUMN_TYPE_NAMES = {
  ACTION_COLUMN: "ACTION_COLUMN",
  BOOLEAN_COLUMN: "BOOLEAN_COLUMN",
  DATE_COLUMN: "DATE_COLUMN",
  NUMBER_COLUMN: "NUMBER_COLUMN",
  TEXT_COLUMN: "TEXT_COLUMN",
};

export const GRID_DEFAULT_COL_DEFS: { defaultColDef: ColDef } = {
  defaultColDef: {
    autoHeaderHeight: true,
    filter: true,
    flex: 1,
    maxWidth: 500,
    minWidth: 100,
    resizable: true,
    sortable: true,
    // ...CENTERED_COL_DEF,
    tooltipValueGetter(params) {
      return params.valueFormatted ? params.valueFormatted : params.value;
    },

    wrapHeaderText: true,
  },
};

type GridProps = { includeDefaultColDefs?: boolean };
export const getGridDefaultProps = ({
  includeDefaultColDefs = true,
}: GridProps = {}): AgGridReactProps => ({
  animateRows: true,
  // rowBuffer: 10,
  columnTypes: COLUMN_TYPES,
  containerStyle: { height: "100%", marginTop: "1rem" },
  // rowSelection: "multiple",
  stopEditingWhenCellsLoseFocus: true,
  ...(includeDefaultColDefs && GRID_DEFAULT_COL_DEFS),
});

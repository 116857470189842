import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom';
import Background from '../images/background-authentication.jpg'
import LogoIMG from '../images/authentication-logo.png'
import LogoWhiteIMG from '../images/logo-white.png'

const Screen = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100vh;
`

const LeftSide = styled.div`
    position: relative;
    width: 65%;
    height: 100%;
    background: url(${({ background }) => background}) no-repeat;
    background-position: center right;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.6;
    }
`

const RightSide = styled.div`
    width: 35%;
    height: 100%;
    background: #fff;
    box-shadow: -1px 0px 15px 0px rgba(0,0,0,0.23);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
`

const RightSideContent = styled.div`
    padding: 0 20px;
    width: 100%;
`

const Logo = styled.img`
    position: relative;
    width: 260px;
    margin: 0 auto 30px auto;
    display: block;
`

const LogoWhite = styled.img`
    position: relative;
    width: 100px;
    margin: 0 auto 30px auto;
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
`

const Link = styled(NavLink)`
    font-size: 18px;
    color: ${({ theme })=> theme.colors.default};

    &:hover {
        color: ${({ theme })=> theme.colors.secondary};
    }
`

const Additional = styled.div`
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid ${({ theme })=> theme.colors.border};
    padding-top: 30px;
`

interface Props {
    children: ReactNode,
    additionalLink?: {
        to: string,
        label: string,
    }
  }

const AuthenticationScreen: FunctionComponent<Props> = ({ children, additionalLink }) => (
    <Screen>
        <LeftSide background={Background}>
            <LogoWhite src={LogoWhiteIMG} />
        </LeftSide>
        <RightSide>
            <RightSideContent>
                <Logo src={LogoIMG} />
                {children}
                <Additional>
                    <Link to={additionalLink.to}>{additionalLink.label}</Link>
                </Additional>
            </RightSideContent>
        </RightSide>
    </Screen>
  )

export default AuthenticationScreen

import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const Colors = {
  Distributor: css`
    background: #b3b3b3;
  `,
  TenantAdmin: css`
    background: #ffb822;
  `,
  TenantSupervisor: css`
    background: #fd27eb;
  `,
  TenantSupport: css`
    background: #ffa500;
  `,
  active: css`
    background: #9ac355;
  `
}

const Tag = styled.div`
    display: inline-block;
    padding: 4px 10px;
    border-radius: 3px;
    color: #fff;
    background: #1dc9b7;
    font-size: 13px;
    ${({ text })=> Colors[text]}
`

interface Props {
    children: string,
  }

const TagComponent: FunctionComponent<Props> = ({ children }) => (
    <Tag text={children}>{children}</Tag>
  )

export default TagComponent

import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import FilterableTableContainer from '../Helpers/FilterableTableContainer';
import * as OrdersStore from '../../store/OrdersReducer';
// @ts-ignore
import { RouteComponentProps, Link } from 'react-router-dom';
import Button from '../Button'
import { DownloadIcon, ViewIcon } from '../../svg'
import TimeAgo from 'react-timeago';
import { dateRender } from '../Utils/actions';
// At runtime, Redux will merge together...
type OrdersProps =
	OrdersStore.OrdersState // ... state we've requested from the Redux store
	& typeof OrdersStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ location: any }>; // ... plus incoming routing parameters

class Orders extends React.Component<OrdersProps>{
	//multiple select?
	//POC action
	state = {
		ordersData: [],
		closeModal: undefined,
		selectedRow: {},
		selectedRowIdx: {}
	}

	constructor(props) {
		super(props);
	}

	downloadOrder = (orderId) => {
		this.props.downloadOrder(orderId);
	}

	editOrder = (props) => {
		let order = props.record,
			editLinkTo = "/order/" + order.id;

		return (
			<>
				<Button color='primary' variant='outlined' icon={ViewIcon} onClick={()=> this.props.history.push(editLinkTo)}>Details</Button>
				<Button variant='outlined' icon={DownloadIcon} onClick={() => this.downloadOrder(order.hermesOrder.id)}>XML</Button>
			</>
		);
	}

	componentDidMount() {
		let callback = (ordersData) => {
			this.setState({
				ordersData: ordersData,
				shouldRender: false
			});
		}
		this.props.getOrdersData(null, callback);
		//register event lsitener for only current tables
		var clickableTrEvent = this.clickableTrEvent;
		this.toggleMapBtnState(false);
		window.requestAnimationFrame(function () {
			var table = document.getElementsByClassName('mainDarkGray')[0];
			clickableTrEvent(table);
		});
	}

	getOrdersDataProps = () => {
		return {
			// Data for the table to display; can be anything
			data: this.state.ordersData,
			// Fields to show in the table, and what object properties in the data they bind to
			fields: [
				//TODO change to depot name
				{ name: 'hermesOrderId', displayName: "Номер поръчка", inputFilterable: true, sortable: true },
				//{ name: 'distributorCustomerName', displayName: "ДИСТРИБУТОР", inputFilterable: true, sortable: true },
				{ name: 'hermesDepotName', displayName: "Депо", inputFilterable: true, exactFilterable: true, sortable: true },

				{ name: 'hermesPocName', displayName: "Пок", inputFilterable: true, exactFilterable: true, sortable: true },
				{ name: 'orderDate', displayName: "Дата на поръчка", render: dateRender },
				{ name: 'isSeen', displayName: "Видяна" },
				{ name: 'isDownloaded', displayName: "Свалена" },
				{ name: 'missingMapping', displayName: "Липсващ мапинг" },
				{ name: 'edit', displayName: "Action", render: this.editOrder }
			],
			namespace: 'OrdersData',
			initialSort: 'hermesOrderId',
			bsLength: 12,
			trClassName: 'clickable-tr order-data-tr',
			tableClassName: 'orders-data',
			tableName: 'List of Orders',
			useBooleanFields: ['isSeen', 'missingMapping', 'isDownloaded']
		}
	}

	resetBtnState() {
		let buttons = document.getElementsByTagName('button');
		for (let i = 0; i < buttons.length; i++) {
			buttons[i].disabled = false;
		}
	}

	toggleMapBtnState = (state) => {
		var createDisabled = this.state.selectedRow ? false : true,
			createBtn = document.getElementById('create-map-btn');
		if (createBtn) {
			(createBtn as HTMLInputElement).disabled = state ? state : createDisabled;
		}
	}

	public render() {
		const { isLoading } = this.props;
		let selectedRow = this.state.selectedRow,
			hermesOrder = selectedRow["hermesOrder"] ? selectedRow["hermesOrder"] : '',
			hermesOrderDetails = hermesOrder["orderDetails"] ? hermesOrder["orderDetails"] : '';
			return (
				<div>
					<FilterableTableContainer
						{... { isLoading }}
						hasActions
						tableProps={this.getOrdersDataProps()}
					/>
				</div>
			)
	}

	clickableTrEvent = (table) => {
		let ref = this;

		if (table) {
			document.addEventListener('click', function (event) {
				var element = (event.target as HTMLElement);

				if (element.tagName === 'BUTTON' || element.tagName === 'INPUT' || element.tagName === 'A') {
					var tableContainer = element.closest('.table-container');

					if (tableContainer) {
						var table = tableContainer.getElementsByTagName('table')[0];
						if (table && table.classList.contains('orders-data')) {
							unhighlightSelectedRows(table);
							ref.setState({
								selectedRow: {},
								selectedRowIdx: undefined,
								shouldRender: false
							});
						}
					}

					ref.toggleMapBtnState(true);
					return false;
				}

				let selectedRow = ref.state.selectedRow,
					selectedRowIdx = ref.state.selectedRow,
					ordersData = ref.state.ordersData,
					parentRow = getParentWithClassname(element, 'clickable-tr');
				if (parentRow) {
					//get mapped rowidx of selected element
					let parentRowIdx = undefined;
					for (let i = 0; i < parentRow.classList.length; i++) {
						if (parentRow.classList[i].slice(0, 4) === 'idx=') {
							parentRowIdx = parentRow.classList[i].slice(4);
							break;
						}
					}					//set selected order row
					if (parentRow.classList.contains('order-data-tr')) {
						unhighlightSelectedRows(parentRow.offsetParent);
						if (selectedRow !== parentRowIdx) {
							var order = ordersData.find(el => el.id === Number(parentRowIdx));
							ref.props.history.push('/order/'+ order["id"]);
						}
						ref.toggleMapBtnState(false);
					}
					parentRow.classList.add('selected-tr');
				}
			});
		}

		function getParentWithClassname(element, classname) {
			if (element.classList.contains(classname)) return element;
			return element.parentElement && getParentWithClassname(element.parentElement, classname);
		}
		function unhighlightSelectedRows(container) {
			//unhighlight selected rows
			if (container) {
				var rows = container.getElementsByClassName('selected-tr');
				while (rows.length >= 1) {
					if (rows[0].classList.contains('clickable-tr')) {
						rows[0].classList.remove('selected-tr');
					}
				}
			}
		}
	}
}
export default connect(
	(state: ApplicationState) => state.orders,
	OrdersStore.actionCreators
)(Orders);

import { createGlobalStyle } from 'styled-components'
import resetCSS from 'styled-reset'
import { setConfiguration } from 'react-grid-system'
import 'react-toastify/dist/ReactToastify.css'

const breakpoints = {
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
}

const mediaQueries = {
    // XS
    xsOnly: `(max-width: ${breakpoints.sm - 1}px)`,

    // SM
    smOnly: `(min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
    smDown: `(max-width: ${breakpoints.md - 1}px)`,
    smUp: `(min-width: ${breakpoints.sm}px)`,

    // MD
    mdOnly: `(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
    mdDown: `(max-width: ${breakpoints.lg - 1}px)`,
    mdUp: `(min-width: ${breakpoints.md}px)`,

    // LG
    lgOnly: `(min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`,
    lgDown: `(max-width: ${breakpoints.xl - 1}px)`,
    lgUp: `(min-width: ${breakpoints.lg}px)`,

    // XL
    xlUp: `(min-width: ${breakpoints.xl}px)`,
}

export const theme = {
  colors: {
    default: '#c2aa73',
    dark: '#6d6d6d',
    light: '#fff',
    semiLight: '#f9f9f9',
    border: '#ebedf2',
    danger: '#e80606',
    primary: '#5d78ff',
    secondary: '#cb2f36',
    success: '#32CD32'
  },
  transition: { default: '0.3s ease-in-out', },
  fonts: { default: '\'Montserrat\', Arial, sans-serif' },
  borderRadius: '30px',
  mediaQueries,
}

//Set grid custom breakpoints
setConfiguration({
  breakpoints: Object.keys(breakpoints).map(key => breakpoints[key]),
  containerWidths: ['100%'],
  gutterWidth: 20,
})

export default createGlobalStyle`
  ${resetCSS}

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }

  a:hover,
  a:focus {
      text-decoration: none;
  }

  body {
    font-family: ${({ theme }) => theme.fonts.default};
    background: ${({ theme }) => theme.colors.semiLight};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }

  .Toastify__toast-container--top-right {
    top: 50px;
    right: 15px;

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        top: auto;
        bottom: 20px;
        left: 20px;
        width: calc(100% - 40px);
      }
    `}
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.default};
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.danger};
  }

  .Toastify__progress-bar {
    background: #f5f5f5;
  }

  .Toastify__toast-body {
    padding: 0 10px;
    font-size: 15px;
  }

  .Toastify__toast {
    border-radius: 6px;
  }

  .Toastify__close-button {
    opacity: 1;
  }

  .popup-overlay {
    margin: 0 !important;
  }

  .popup-content {
    width: auto !important;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 3px;
  }

   .mt-10 {
    margin-top: 10px !important;
    }


`

import * as React from "react";
import { connect } from "react-redux";
import FilterableTableContainer from "../Helpers/FilterableTableContainer";
import { ApplicationState } from "../../store";
import * as UsersStore from "../../store/UsersReducer";
import withRouter, { WithRouterHocProps } from "../../helpers/WithRouterHOC";

type UsersProps = UsersStore.UsersState & typeof UsersStore.actionCreators;
class UsersList extends React.PureComponent<UsersProps & WithRouterHocProps> {
  constructor(props) {
    super(props);
    this.props.getUserList();
  }

  componentDidMount() {
    this.props.getUserList();
  }

  public render() {
    const { isLoading, userList } = this.props;
    let props = {
      // Data for the table to display; can be anything
      data: userList,
      // Fields to show in the table, and what object properties in the data they bind to
      fields: [
        {
          name: "firstName",
          displayName: "Name",
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "username",
          displayName: "Username",
          inputFilterable: true,
          sortable: true,
        },
        {
          name: "email",
          displayName: "Email",
          inputFilterable: true,
          exactFilterable: true,
          sortable: true,
        },
        {
          name: "roleId",
          displayName: "Role ID",
          inputFilterable: true,
          sortable: true,
        },
        { name: "edit", displayName: "Action" },
      ],
      namespace: "People",
      initialSort: "firstName",
      bsLength: 12,
      useEditAction: true,
      tableName: "Users",
    };
    return <FilterableTableContainer {...{ isLoading }} tableProps={props} />;
  }
}
export default withRouter(
  connect(
    (state: ApplicationState) => state.users,
    UsersStore.actionCreators,
  )(UsersList),
);

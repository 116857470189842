import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import * as UploadDataStore from "../../store/UploadDataReducer";
import styled from "styled-components";
import Button from "../Button";
import { FileIcon } from "../../svg";
import Loader from "../Loader";
import Headline from "../Headline";
import Box from "../Box";
import { Row, Col } from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import {
  COLUMN_TYPE_NAMES,
  getGridDefaultProps,
} from "constants/agGridConstants";
import { dateFormatter } from "components/Utils/agGrid/valueFormatters";
import { ColDef } from "ag-grid-community";
// Styled Components
const UploadWrapper = styled.div`
  position: relative;
  width: 50%;
  max-width: 600px;
  background: #fff;
  border: 1px solid #e2e5ec;
  margin-bottom: 20px;
`;

const UploadInput = styled.input`
  background: #fff;
  width: 100%;
  border-radius: 3px;
  padding: 15px;
  border: 0;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 15px;
  text-align: center;

  &::-webkit-file-upload-button {
    -webkit-appearance: none;
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;
    font-size: 14px;
    padding: 12px 15px;
    border: 0;
    border-radius: 3px;
    margin-right: 20px;
    display: block;
    transition: background ${({ theme }) => theme.transition.default};
    cursor: pointer;

    &:focus,
    &:hover,
    &:active {
      outline: none;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.default};
    }
  }
`;
const UploadData = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    props.setErrorMessage("");
    props.getLastTen();
    toggleMapBtnState(true);
  }, []);

  const toggleMapBtnState = (state: boolean) => {
    const uploadButton = document.getElementById(
      "upload-button",
    ) as HTMLButtonElement;
    if (uploadButton) {
      uploadButton.disabled = state;
    }
  };

  const handleChangeFile = (event) => {
    let files = event.target.files;
    setSelectedFiles([]);
    props.setErrorMessage("");

    for (let id = 0; id < files.length; id++) {
      var file = files[id];
      if (!validateFile(file)) {
        toggleMapBtnState(true);
        props.setErrorMessage(
          "Incorrect file format. Only XML format is supported.",
        );
        return;
      }
    }
    setSelectedFiles(files);
    toggleMapBtnState(false);
  };

  const validateFile = (file) => {
    if (!file) return false;
    var allowedExtension = ["xml"];
    var fileExtension = file.name.split(".").pop().toLowerCase();
    var isValidFile = false;

    for (var index in allowedExtension) {
      if (fileExtension === allowedExtension[index]) {
        isValidFile = true;
        break;
      }
    }

    return isValidFile;
  };

  const onClickHandler = () => {
    for (let id = 0; id < selectedFiles.length; id++) {
      var file = selectedFiles[id],
        fileName = file.name;
      const fileData = new FormData();
      fileData.append("file", file);
      props.uploadFile(fileData, fileName);
    }
    toggleMapBtnState(true);
  };

  const columnDefs: ColDef[] = useMemo<ColDef[]>(
    () => [
      { field: "fileName", minWidth: 500, type: COLUMN_TYPE_NAMES.TEXT_COLUMN },
      { field: "depotName" },
      { field: "extractDate", valueFormatter: dateFormatter },
      { field: "dateCreated", valueFormatter: dateFormatter },
      { field: "status" },
      { field: "totalEntries" },
      { field: "datesCount" },
      { field: "datesList" },
    ],
    [],
  );

  return (
    <>
      <Headline>Upload Data</Headline>
      <UploadWrapper>
        <UploadInput type="file" multiple onChange={handleChangeFile} />
        <Loader
          size="small"
          isLoading={props.isLoading}
          background="semiLight"
        />
      </UploadWrapper>
      <div>
        <Button
          icon={FileIcon}
          size="extraLarge"
          id="upload-button"
          onClick={onClickHandler}
        >
          Upload Data
        </Button>
      </div>
      <Row>
        <Col md={12}>
          <Box
            title="Last ten uploaded files"
            hasMargin
            hasMarginTop
            hasContentGutter
          >
            <AgGridReact
              getRowId={(params) => params.data.id}
              rowData={props.lastTen}
              domLayout="autoHeight"
              columnDefs={columnDefs}
              {...getGridDefaultProps()}
            />
          </Box>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.uploadData,
});

const mapDispatchToProps = {
  ...UploadDataStore.actionCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadData);
